import * as axios from "axios";

const instance = axios.create({
	baseURL: "https://e-api.com.ua/api",
	// baseURL: "https://e-api.ithinkers.fun/api",
	// baseURL: "https://web.e-admin.com.ua/api/api.php",
});

// export const firebaseAPI = {
// 	getConfig(login) {
// 		// console.log('login', login);
// 		var body = new FormData();
// 		// body.append('login', login);
// 		body.append("login", login);
// 		return instance.post(`/`, body).then((response) => {
// 			return response.data;
// 		});
// 	},
// };

// Еще не сделан

export const firebaseAPI = {
	getConfig(login) {
		return instance
			.get(`/v1/client/firebaseConfig/${login}`)
			.then((response) => {
				const config = response.data.config;
				const isValidConfig = Object.keys(config).length === 0;

				if (isValidConfig) {
					throw new Error("Файл конфигурации пустой.");
				}

				return response.data.config;
			});
	},
};
