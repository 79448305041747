import React, {Component} from 'react';

import {connect} from 'react-redux';
import { withRouter } from "react-router";
import { withFirebase } from '../../../firebase'

import {editShops} from '../../../reducers/shops';
import Products from './index';

class ProductsContainer extends Component {

    constructor(){
        super();

        this.state = {
            preloader: false,
            keyShop: null,
        }
    }

    componentDidMount(){
        this.getKeyShop();
    }

    getKeyShop = () => {
        let id = this.props.match.path.split('/');
        id = id[id.length - 1];

        this.setState({ keyShop: id });
    }

    editShops = (value, params) => {
        let data = {
            key: this.state.keyShop,
            value,
            params,
        }
        console.log('editShops', data);

        if (params === 'noDiscountForCategorieIds') {
            this.props.handleCategoriesPromocode(true);
        } else {
            this.props.handleProductsPromocode(true);
        }

        this.props.editShops(data);
    }

    saveData = () => {
        const shops = this.props.shops[this.state.keyShop];
        let noDiscountForCategorieIds;
        let noDiscountForProductIds;

        if (shops) {
            noDiscountForCategorieIds = shops.noDiscountForCategorieIds
            noDiscountForProductIds = shops.noDiscountForProductIds

            let noDiscountForCategorieIdsIsValid = noDiscountForCategorieIds === undefined ? [] : noDiscountForCategorieIds;
            let noDiscountForProductIdsIsValid = noDiscountForProductIds === undefined ? [] : noDiscountForProductIds;

            // console.log('noDiscountForCategorieIdsIsValid', noDiscountForCategorieIdsIsValid);
            // console.log('noDiscountForProductIdsIsValid', noDiscountForProductIdsIsValid);

            this.setState({preloader: true})
            this.props.firebase.bd
            .ref(`${this.props.login}/settings/City/${this.state.keyShop}/`)
            .update({
                noDiscountForCategorieIds: noDiscountForCategorieIdsIsValid,
                noDiscountForProductIds: noDiscountForProductIdsIsValid
            })
            .then(res => {
                this.setState({preloader: false})
                this.props.handleCategoriesPromocode(false);
                this.props.handleProductsPromocode(false);
            })
        }
    }

    render() {
        const shop = this.props.shops[this.state.keyShop];
        return (
            <>
                <Products   loadStatus={this.state.loadStatus}
                            saveData={this.saveData}
                            editShops={this.editShops}
                            noDiscountForCategorieIds={shop ? shop.noDiscountForCategorieIds : []}
                            noDiscountForProductIds={shop ? shop.noDiscountForProductIds : []}
                            handleCategoriesPromocode={this.props.handleCategoriesPromocode}
                            handleProductsPromocode={this.props.handleProductsPromocode}
                />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        login: state.auth.login,
        shops: state.shops.shops,
        appSettings: state.shops.appSettings,
    }
}

export default connect(mapStateToProps, {editShops})(withRouter(withFirebase(ProductsContainer)));