import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../../../firebase";

import {
	addShops,
	updateLanguages,
	updateDefaultLanguage,
	// updateCategoriesDispatch,
	// updateProductsDispatch,
	updateKeyShopDispatch,
	updateKeyBrandDispatch,
	addAdminPermission,
} from "../../../reducers/shops";

import DefaultScreen from "./defaultScreen";

const DefaultScreenContainer = (props) => {
	const dispatch = useDispatch();
	const city = useSelector((state) => state.shops.shops);
	const keyShop = useSelector((state) => state.shops.keyShop);
	const keyBrand = useSelector((state) => state.shops.keyBrand);
	const categories = useSelector((state) => state.shops.categories);
	const products = useSelector((state) => state.shops.products);
	const login = useSelector((state) => state.auth.login);

	const [open, setOpen] = useState(false);
	const [isDirtyWorkingHours, setIsDirtyWorkingHours] = useState(false);
	const [openSettings, setOpenSettings] = useState(false);
	const [listCity, setListCity] = useState([]);
	const [defaultHiddenCategories, setDefaultHiddenCategories] = useState(null);
	const [defaultHiddenProducts, setDefaultHiddenProducts] = useState(null);
	const [defaultCity, setDefaultCity] = useState(null);
	const [loadCity, setLoadCity] = useState(true);
	const [openCategory, setOpenCategory] = useState(null);
	const [openProducts, setOpenProducts] = useState(null);
	const [category, setCategory] = useState(null);
	const [adminPermission, setAdminPermission] = useState(null);
	const [adminSettins, setAdminSettins] = useState(null);
	const [categoriesMenu, setCategoriesMenu] = useState(false);
	const [categoriesPromocode, setCategoriesPromocode] = useState(false);
	const [productsMenu, setProductsMenu] = useState(false);
	const [productsPromocode, setProductsPromocode] = useState(false);
	const [preloader, setPreloader] = useState(false);

	useEffect(() => {
		getCities();
		getDefaultCity();
		getAdminPermission();
		getAdminSettins();
		getDefaultHiddenCategories();
		getDefaultHiddenProducts();
		// // getCategories();
		getProductsState();
	}, []);

	props.firebase.bd.ref(`web/adminPermission`).once("value", (snapshot) => {
		const admin = snapshot.val();
		localStorage.setItem("adminPermission", JSON.stringify(admin));
	});

	const getProductsState = () => {
		let isProducts = props.location.pathname.match("/page/menu/products");

		if (isProducts) {
			getDefaultLanguage();
			getLanguages();
			//   getAdminPermission();
			//   getCategories();
			//   getProducts();
		}
	};

	// const getProducts = () => {
	//     props.firebase.bd.ref(`${login}/products`).once("value", (snapshot) => {
	//         let products = snapshot.val();

	//         if (products) updateProductsDispatch(dispatch, products);
	//     });
	// }

	// const getCategories = () => {
	//     props.firebase.bd.ref(`${login}/categories`).once("value", (snapshot) => {
	//         let categories = snapshot.val();
	//         const categoriesIds = categories ? Object.keys(categories) : [];

	//         if (categories) {
	//             props.firebase.bd.ref(`${login}/settings/Category/categoriesOrder`).once("value", (snapshot) => {
	//             let categoriesOrder = snapshot.val();
	//             let data = {};

	//             if (categoriesOrder) {
	//                 let categoriesOrders = [];
	//                 const categoriesOrderIds = Object.values(categoriesOrder);

	//                 categoriesIds.map(id => {
	//                     categoriesOrderIds.map(orderId => {
	//                         if (orderId === id) {
	//                         categoriesOrders.push(id);
	//                         }
	//                     });
	//                 });

	//                 for (let key in categoriesOrders) {
	//                     if (categories[categoriesOrder[key]]) {
	//                         data[categoriesOrder[key]] = categories[categoriesOrder[key]]
	//                     }
	//                 }
	//             }

	//             if (Object.keys(data).length > 0) updateCategoriesDispatch(dispatch, data);
	//             if (Object.keys(data).length === 0 && categories) updateCategoriesDispatch(dispatch, categories);
	//             });
	//         }
	//     });
	// }

	const getCities = () => {
		setLoadCity(true);
		const cityId = +JSON.parse(localStorage.getItem("chosenCity"));
		const adminPermission = JSON.parse(localStorage.getItem("adminPermission"));
		const chosenBrand = +JSON.parse(localStorage.getItem("chosenBrand"));

		props.firebase.bd.ref(`web/settings`).once("value", (snapshot) => {
			let items = snapshot.val();

			let listCity = [];

			let city = items.city;
			// let city = items.City;
			for (let item in city) {
				listCity.push({
					...city[item],
					key: item,
				});
			}

			console.log(listCity);

			let data = adminPermission?.newAdmin
				? {
						shops: listCity,
						appSettings: listCity[cityId].brands[chosenBrand]?.appSettings,
						category: listCity[cityId].brands[chosenBrand]?.category,
						mainSettings: items.mainSettings,
				  }
				: {
						shops: listCity,
						appSettings: listCity[cityId ? cityId : 0].appSettings,
						category: listCity[cityId ? cityId : 0].category,
						mainSettings: items.mainSettings,
				  };
			addShops(dispatch, data);
			setLoadCity(false);
			setListCity(listCity);
		});
	};

	const getDefaultCity = () => {
		let cityId = window.location.pathname.split("/");
		cityId = cityId[cityId.length - 1];

		setLoadCity(true);
		props.firebase.bd
			.ref(`web/settings/city/${cityId}`)
			.on("value", (snapshot) => {
				let defaultCity = snapshot.val();
				setDefaultCity(defaultCity);
				setLoadCity(false);
				// console.log('defaultCity', defaultCity);
			});
	};

	const getAdminPermission = () => {
		props.firebase.bd.ref(`web/adminPermission`).once("value", (snapshot) => {
			let adminPermission = snapshot.val();

			setAdminPermission(adminPermission);
			addAdminPermission(dispatch, adminPermission);
		});
	};

	const getAdminSettins = () => {
		props.firebase.bd.ref(`web/adminSettins`).once("value", (snapshot) => {
			let val = snapshot.val();
			if (!adminSettins) setAdminSettins(val);
		});
	};

	const getDefaultHiddenCategories = () => {
		const adminPermission = JSON.parse(localStorage.getItem("adminPermission"));
		const chosenBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		const chosenCity = JSON.parse(localStorage.getItem("chosenCity"));

		let cityId = window.location.pathname.split("/");
		cityId = cityId[cityId.length - 1];

		setLoadCity(true);

		if (adminPermission?.newAdmin) {
			props.firebase.bd
				.ref(
					`web/settings/city/${chosenCity}/brands/${chosenBrand}/category/hiddenCategories`
				)
				.once("value", (snapshot) => {
					let val = snapshot.val();
					setDefaultHiddenCategories(val);
					setLoadCity(false);
				});
		} else {
			props.firebase.bd
				.ref(`web/settings/city/${cityId}/category/hiddenCategories`)
				.once("value", (snapshot) => {
					let val = snapshot.val();
					setDefaultHiddenCategories(val);
					setLoadCity(false);
				});
		}
	};

	const getDefaultHiddenProducts = () => {
		const adminPermission = JSON.parse(localStorage.getItem("adminPermission"));
		const chosenBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		const chosenCity = JSON.parse(localStorage.getItem("chosenCity"));

		let cityId = window.location.pathname.split("/");
		cityId = cityId[cityId.length - 1];
		setLoadCity(true);

		if (adminPermission?.newAdmin) {
			props.firebase.bd
				.ref(
					`web/settings/city/${chosenCity}/brands/${chosenBrand}/category/hiddenProducts`
				)
				.once("value", (snapshot) => {
					let val = snapshot.val();
					setDefaultHiddenProducts(val);
					setLoadCity(false);
				});
		} else {
			props.firebase.bd
				.ref(`web/settings/city/${cityId}/category/hiddenProducts`)
				.once("value", (snapshot) => {
					let val = snapshot.val();
					setDefaultHiddenProducts(val);
					setLoadCity(false);
				});
		}
	};

	const getDefaultLanguage = () => {
		props.firebase.bd
			.ref(`web/adminPermission/defaultLanguage`)
			.once("value", (snapshot) => {
				let defaultLanguage = snapshot.val();
				console.log("defaultLanguage", defaultLanguage);
				if (defaultLanguage) updateDefaultLanguage(dispatch, defaultLanguage);
			});
	};

	const getLanguages = () => {
		props.firebase.bd.ref(`web/languages`).once("value", (snapshot) => {
			let languages = snapshot.val();
			console.log("lang", languages);
			if (languages) updateLanguages(dispatch, languages);
		});
	};

	const handleDrawerOpen = () => setOpen(true);
	const handleDrawerClose = () => setOpen(false);
	const handleCategoriesMenu = (value) => setCategoriesMenu(value);
	const handleProductsMenu = (value) => setProductsMenu(value);
	const handleCategoriesPromocode = (value) => setCategoriesPromocode(value);
	const handleProductsPromocode = (value) => setProductsPromocode(value);
	const handleIsDirtyWorkingHours = (value) => setIsDirtyWorkingHours(value);

	// const openSettings = () => {
	//     if(!openSettings && listCity.length === 0) getCities();

	//     setOpenSettings(openSettings ? false : true);
	//     setCity(city);
	// }

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth >= 960) {
				handleDrawerClose(false);
			}
		};

		window.addEventListener("resize", handleResize);

		// Проверяем размер окна при монтировании компонента
		handleResize();

		// Убираем слушатель при размонтировании компонента
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const chooseCity = (key) => {
		localStorage.setItem("chosenCity", JSON.stringify(Number(key)));
		updateKeyShopDispatch(dispatch, keyShop === key ? null : key);
	};

	const chooseBrand = (key) => {
		updateKeyBrandDispatch(dispatch, keyBrand === key ? null : key);
	};

	const handleOpen = (category) => {
		setOpenCategory(category === openCategory ? null : category);
	};

	const handleProductOpen = (category) => {
		if (
			openProducts !== category &&
			products.length <= 0 &&
			categories.length <= 0
		) {
			setPreloader(true);
			//   getCategories();
			//   getProducts();
			getLanguages();
			getDefaultLanguage();
			setTimeout(() => setPreloader(false), 200);
		}

		setOpenProducts(category === openProducts ? null : category);
	};

	return (
		<DefaultScreen
			open={open}
			preloader={preloader}
			handleDrawerClose={handleDrawerClose}
			openSettings={openSettings}
			chooseCity={chooseCity}
			chooseBrand={chooseBrand}
			keyShop={keyShop}
			keyBrand={keyBrand}
			loadCity={loadCity}
			city={city}
			defaultCity={defaultCity}
			defaultHiddenCategories={defaultHiddenCategories}
			defaultHiddenProducts={defaultHiddenProducts}
			getDefaultHiddenCategories={getDefaultHiddenCategories}
			getDefaultHiddenProducts={getDefaultHiddenProducts}
			openCategory={openCategory}
			openProducts={openProducts}
			handleOpen={handleOpen}
			handleProductOpen={handleProductOpen}
			adminPermission={adminPermission}
			adminSettins={adminSettins}
			categoriesMenu={categoriesMenu}
			productsMenu={productsMenu}
			categoriesPromocode={categoriesPromocode}
			productsPromocode={productsPromocode}
			isDirtyWorkingHours={isDirtyWorkingHours}
			handleIsDirtyWorkingHours={handleIsDirtyWorkingHours}
			handleDrawerOpen={handleDrawerOpen}
			handleCategoriesMenu={handleCategoriesMenu}
			handleProductsMenu={handleProductsMenu}
			getDefaultCity={getDefaultCity}
			handleCategoriesPromocode={handleCategoriesPromocode}
			handleProductsPromocode={handleProductsPromocode}
		/>
	);
};

export default withFirebase(withRouter(DefaultScreenContainer));
