import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import TableForm from './TableForm';

const WorkTimeComponent = (props) => {
    const classes = useStyles();
    const workingHours = useSelector(state => state.shops.workingHours);
    const isMin600 = useMediaQuery('(max-width: 599px)');
    const isMax600 = useMediaQuery('(min-width: 600px)');

    useEffect(() => {
        const cityId = props.match.params.city;

        if (cityId !== props.keyShop) {
            // console.log('--- changed ---');
            props.getWorkingHours();
        }
    }, [props.match.params.city, props.keyShop]);

    return (    
        <div className={classes.root}>
            {isMin600 && <Typography className={classes.titleMobile} variant="h1">Время работы</Typography>}
            {isMin600 && (
                <div className={classes.tabContent}>
                    <TableForm saveWorkTime={props.saveWorkTime} preloader={props.preloader} initialValues={workingHours} />
                </div>
            )}
            {isMax600 && (
                <div className={classes.desctopWrapper}>
                    <Typography className={classes.title} variant="h1">Время работы</Typography>
                    <TableForm saveWorkTime={props.saveWorkTime} preloader={props.preloader} initialValues={workingHours} />
                </div>
            )}
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
    
    },
    desctopWrapper: {
        padding: '24px 16px 24px 16px',
        background: 'white',
        border:' 0.5px solid #DADCE0',
        boxSizing: 'border-box',
        borderRadius: '5px 5px 0px 0px',
    },
    tabContent: {
        [theme.breakpoints.down("600")]: {
            padding: '16px 8px',
            background: 'white',
            border: '1px solid #E5E5E5',
            borderRadius: 10,
        },
    },
    title: {
        marginBottom: 16,
        fontSize: 22,
        fontWeight: 'bold',
        lineHeight: '29px',
    },
    titleMobile: {
        marginTop: 16,
        marginBottom: 8,
        fontSize: 22,
        fontWeight: 'bold',
        lineHeight: '29px',
    }
}));

export default withRouter(WorkTimeComponent);