import React, { useState, useEffect } from 'react';
import { InputField, CustomInputField } from './inputFields';

const CityFields = (props) => {
    const [checked, setChecked] = useState(false);
    const [count, setCount] = useState(0);
    const {initialValues} = props;
    
    useEffect(() => {
        if (count === 0) {
            if (initialValues && !initialValues.delivery) {
                setChecked(true);
                setCount(count + 1);
            } 
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValues]);

    const handleCheckedChange = (event) => {
        const checked = event.target.checked;
        setChecked(checked);
    };

    return (
        <div className={props.className}>
            <InputField
                name="deliveryLimit" 
                title="Минимальная сумма для доставки"
                placeholder="Введите сумму мин. заказа"
                validMsg="Введите сумму заказа" />

            <InputField
                name="deliveryFreeLimit" 
                title="Бесплатная доставка от"
                placeholder="Введите сумму мин. доставки"
                validMsg="Введите бесплатную доставку" />

            <CustomInputField
                name="delivery"
                checked={checked}
                handleCheckedChange={handleCheckedChange}
                title="Стоимость доставки"
                popover={false}
                checkBox={true}
                placeholder="Введите стоимость доставки"
                validMsg="Введите стоимость доставки"
                checkboxTitle="Бесплатная доставка" />
        </div>
    )
}

export default CityFields;