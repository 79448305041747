import React from "react";
import * as ROUTES from "../../../constants/routes";
import { PromotionIcon } from "./icons";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addShops } from "../../../reducers/shops";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import SettingsIcon from "@material-ui/icons/Settings";
import MobileOffIcon from "@material-ui/icons/MobileOff";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Notifications from "@material-ui/icons/Notifications";
import AlarmIcon from "@material-ui/icons/Alarm";
import CommentIcon from "@material-ui/icons/Comment";
import PaymentIcon from "@material-ui/icons/Payment";
import AccessTime from "@material-ui/icons/AccessTime";
import DepartureBoardIcon from "@material-ui/icons/DepartureBoard";
import List from "@material-ui/core/List";
import Drawer from "@material-ui/core/Drawer";
import Loading from "../../../components/Loading";
import AssignmentIcon from "@material-ui/icons/Assignment";
import NotesIcon from "@material-ui/icons/Notes";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import FolderIcon from "@material-ui/icons/Folder";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";

// import IconButton from '@material-ui/core/IconButton';
// import Divider from '@material-ui/core/Divider';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import ModalAlert from "../../../components/ModalComponent";

import useStyles from "./styles";

const Navigation = (props) => {
	const classes = useStyles();
	const {
		open,
		width,
		isMenu,
		isPush,
		isReviews,
		isBlackList,
		isPromotion,
		isValidPayCard,
		validPromocodesUrl,
		handleClose,
		handleClickSubmit,
		handlePushClick,
		handleMenuClick,
		handleReviewsClick,
		handlePromotionClick,
		handleSettingsClick,
		handleProductsClick,
		handleBlackListCheck,
	} = props;

	const isNewAdmin = props.adminPermission?.newAdmin;

	const changeCity = (key) => {
		localStorage.setItem("chosenCity", JSON.stringify(Number(key)));
		props.chooseCity(key);
	};

	const dispatch = useDispatch();

	const changeBrand = (item, key) => {
		localStorage.setItem("chosenBrand", JSON.stringify(key));
		localStorage.setItem("currentBrand", JSON.stringify(item));
		props.chooseBrand(item.id);
		console.log("propsmin brand click", props);
		let data = {
			shops: props.listCity,
			appSettings:
				props.listCity[Number(localStorage.getItem("chosenCity"))].brands[key]
					.appSettings,
			category:
				props.listCity[Number(localStorage.getItem("chosenCity"))].brands[key]
					.category,
			hiddenCategories:
				props.listCity[Number(localStorage.getItem("chosenCity"))].brands[key]
					.category.hiddenCategories,
			hiddenProducts:
				props.listCity[Number(localStorage.getItem("chosenCity"))].brands[key]
					.category.hiddenProducts,
			mainSettings: props.mainSettings,
		};

		// console.log('update shops', data);
		addShops(dispatch, data);
	};

	return (
		<Drawer
			className={classes.drawer}
			variant={
				width !== "lg" && width !== "md" && width !== "xl"
					? "temporary"
					: "persistent"
			}
			anchor="left"
			open={
				width !== "lg" && width !== "md" && width !== "xl" ? props.open : true
			}
			onClose={props.handleDrawerClose}
			classes={{
				paper: classes.drawerPaper,
			}}
		>
			<List className={classes.listWrapper}>
				{/*<ListItem button onClick={() => props.handleOpen('menu')}>
          <ListItemIcon className={classes.iconMenu}><AssignmentIcon/></ListItemIcon>
          <ListItemText primary="Меню" />
          {props.openCategory === 'menu' ? <ExpandLess /> : <ExpandMore />}
          {props.openCategory === 'menu' ? <ExpandLess /> : <ExpandMore />}
      </ListItem>*/}

				<ModalAlert
					title="Вы хотите уйти?"
					description="Ваши данные не будут сохранены."
					handleProductsClick
					isReviews
					isPush
					// open={open}
					handleClose={handleClose}
					handleClickSubmit={handleClickSubmit}
				/>

				<Collapse
					className={classes.collapse}
					in={props.openCategory === "menu"}
					timeout="auto"
					unmountOnExit
				>
					<List component="div" className={classes.nestedLink}>
						<NavLink
							to={`/page/test`}
							className={classes.link}
							activeClassName={classes.active}
						>
							<ListItem button className={classes.nested}>
								<ListItemIcon className={classes.iconMenu}>
									{" "}
									<AddShoppingCartIcon />{" "}
								</ListItemIcon>
								<ListItemText primary={"Товари"} />
							</ListItem>
						</NavLink>
						<NavLink
							to={ROUTES.CATEGORIES}
							className={classes.link}
							activeClassName={classes.active}
						>
							<ListItem button className={classes.nested}>
								<ListItemIcon className={classes.iconMenu}>
									{" "}
									<NotesIcon />{" "}
								</ListItemIcon>
								<ListItemText primary={"Категорії"} />
							</ListItem>
						</NavLink>
					</List>
				</Collapse>

				{/* {props.adminPermission && props.adminPermission.menu && (
          <div onClick={handleMenuClick} className={classes.link} style={{
            background: isMenu ? 'rgba(50, 109, 255, .1)' : 'inherit',
          }}>
            <ListItem button>
              <ListItemIcon className={classes.iconMenu}><AssignmentIcon className={classes.icon} style={{ color: isMenu ? '#326dff' : 'inherit', fill: isMenu ? '#326dff' : '' }} /></ListItemIcon>
              <ListItemText style={{ color: isMenu ? '#326dff' : 'inherit' }} primary={'Меню'} />
            </ListItem>
          </div>
        )} */}
				{props.adminPermission && props.adminPermission.pushControl && (
					<div
						onClick={handlePushClick}
						className={classes.link}
						style={{
							background: isPush ? "rgba(50, 109, 255, .1)" : "inherit",
						}}
					>
						<ListItem button>
							<ListItemIcon className={classes.iconMenu}>
								<Notifications
									style={{
										color: isPush ? "#326dff" : "inherit",
										fill: isPush ? "#326dff" : "",
									}}
								/>
							</ListItemIcon>
							<ListItemText
								style={{ color: isPush ? "#326dff" : "inherit" }}
								primary={"Push-уведомление"}
							/>
						</ListItem>
					</div>
				)}

				{props.adminPermission && props.adminPermission.promotion && (
					<div
						onClick={handlePromotionClick}
						className={classes.link}
						style={{
							background: isPromotion ? "rgba(50, 109, 255, .1)" : "inherit",
						}}
					>
						<ListItem button>
							<ListItemIcon className={classes.iconMenu}>
								<PromotionIcon
									style={{ fill: isPromotion ? "#326dff" : "inherit" }}
								/>
							</ListItemIcon>
							<ListItemText
								style={{ color: isPromotion ? "#326dff" : "inherit" }}
								primary={"Продвижение"}
							/>
						</ListItem>
					</div>
				)}

				<ListItem
					className={classes.settingsListItem}
					button
					onClick={() => handleSettingsClick()}
				>
					<ListItemIcon className={classes.iconMenu}>
						<SettingsIcon />
					</ListItemIcon>
					<ListItemText className={classes.listText} primary="Налаштування" />
					{props.openCategory === "settings" ? <ExpandLess /> : <ExpandMore />}
				</ListItem>

				{isNewAdmin ? (
					<>
						<Collapse
							className={classes.collapse}
							in={props.openCategory === "settings"}
							timeout="auto"
							unmountOnExit
						>
							{props.loadCity ? (
								<div className={classes.load}>
									<Loading color="#326dff" size={24} />
								</div>
							) : (
								<List component="div">
									{props.listCity.map((cityItem) => {
										const brandIds = cityItem?.brands?.map((item) => item.id);
										return (
											<div key={cityItem.key}>
												<ListItem
													key={cityItem.key}
													button
													onClick={() => changeCity(cityItem.key)}
													className={classes.nested}
													style={{
														color:
															props.city === cityItem.key ? "#326dff" : "#000",
													}}
												>
													<ListItemText
														primary={cityItem.name.uk || cityItem.name}
													/>
													{props.city === cityItem.key ? (
														<ExpandLess />
													) : (
														<ExpandMore />
													)}
												</ListItem>
												{/* <Collapse className={classes.collapse} in={(props.city === cityItem.key) ? true : false} timeout="auto" unmountOnExit> */}
												<Collapse
													className={classes.collapse}
													in={
														props.city === cityItem.key ||
														brandIds.includes(props.brand)
															? true
															: false
													}
													timeout="auto"
													unmountOnExit
												>
													{cityItem?.brands?.map((item, index) => {
														return (
															<div key={item.id}>
																<ListItem
																	key={item.id}
																	button
																	onClick={() => changeBrand(item, index)}
																	className={classes.nested}
																	style={{
																		color:
																			props.brand === item.id
																				? "#326dff"
																				: "#000",
																	}}
																>
																	<ListItemText
																		primary={item.name.uk || item.name}
																		style={{ marginLeft: 10 }}
																	/>

																	{props.brand === item.id ? (
																		<ExpandLess />
																	) : (
																		<ExpandMore />
																	)}
																</ListItem>
																<Collapse
																	className={classes.collapse}
																	in={props.brand === item.id ? true : false}
																	timeout="auto"
																	unmountOnExit
																>
																	<List
																		component="div"
																		className={classes.nestedLink}
																	>
																		{props.adminPermission &&
																			props.adminPermission.menu && (
																				<div
																					onClick={handleMenuClick}
																					className={classes.link}
																					style={{
																						background: isMenu
																							? "rgba(50, 109, 255, .1)"
																							: "inherit",
																					}}
																				>
																					<ListItem button>
																						<ListItemIcon
																							className={classes.iconMenu}
																						>
																							<AssignmentIcon
																								className={classes.icon}
																								style={{
																									color: isMenu
																										? "#326dff"
																										: "inherit",
																									fill: isMenu ? "#326dff" : "",
																								}}
																							/>
																						</ListItemIcon>
																						<ListItemText
																							style={{
																								color: isMenu
																									? "#326dff"
																									: "inherit",
																							}}
																							primary={"Стоп-лист"}
																						/>
																					</ListItem>
																				</div>
																			)}

																		{props.adminPermission &&
																			props.adminPermission.blackList && (
																				<NavLink
																					// onClick={handleReviewsClick}
																					to={`/page/blacklist/${item.id}`}
																					className={classes.link}
																					style={{
																						background: isReviews
																							? "rgba(50, 109, 255, .1)"
																							: "inherit",
																					}}
																				>
																					<ListItem button>
																						<ListItemIcon
																							className={classes.iconMenu}
																						>
																							<AssignmentIcon
																								className={classes.icon}
																								style={{
																									color: isBlackList
																										? "#326dff"
																										: "inherit",
																									fill: isBlackList
																										? "#326dff"
																										: "",
																								}}
																							/>
																						</ListItemIcon>
																						<ListItemText
																							style={{
																								color: isBlackList
																									? "#326dff"
																									: "inherit",
																							}}
																							primary={"Черный список"}
																						/>
																					</ListItem>
																				</NavLink>
																			)}
																		{props.adminPermission &&
																			(props.adminPermission
																				? props.adminPermission
																						.productReviews ||
																				  props.adminPermission.appReviews ||
																				  props.adminPermission.orderReviews !==
																						undefined
																					? props.adminPermission.orderReviews
																					: true
																				: true) && (
																				<NavLink
																					// onClick={handleReviewsClick}
																					to={`/page/reviews/${item.id}`}
																					className={classes.link}
																					style={{
																						background: isReviews
																							? "rgba(50, 109, 255, .1)"
																							: "inherit",
																					}}
																				>
																					<ListItem button>
																						<ListItemIcon
																							className={classes.iconMenu}
																						>
																							<CommentIcon
																								style={{
																									color: isReviews
																										? "#326dff"
																										: "inherit",
																									fill: isReviews
																										? "#326dff"
																										: "",
																								}}
																							/>
																						</ListItemIcon>
																						<ListItemText
																							style={{
																								color: isReviews
																									? "#326dff"
																									: "inherit",
																							}}
																							primary={"Отзывы"}
																						/>
																					</ListItem>
																				</NavLink>
																			)}
																		{props.adminPermission &&
																			!!props.adminPermission.delivery && (
																				<NavLink
																					to={`/page/delivery/${item.key}`}
																					className={classes.link}
																					activeClassName={classes.active}
																				>
																					<ListItem
																						button
																						className={classes.nested}
																					>
																						<ListItemIcon
																							className={classes.iconMenu}
																						>
																							<DepartureBoardIcon />
																						</ListItemIcon>
																						<ListItemText
																							primary={"Условия доставки"}
																						/>
																					</ListItem>
																				</NavLink>
																			)}
																		{props.adminPermission &&
																			props.adminPermission.workingHours && (
																				<NavLink
																					to={`/page/worktime/${item.key}`}
																					className={classes.link}
																					activeClassName={classes.active}
																				>
																					<ListItem
																						button
																						className={classes.nested}
																					>
																						<ListItemIcon
																							className={classes.iconMenu}
																						>
																							<AccessTime />
																						</ListItemIcon>
																						<ListItemText
																							primary={"Время работы"}
																						/>
																					</ListItem>
																				</NavLink>
																			)}
																		{props.adminPermission &&
																			props.adminPermission.pickupControl && (
																				<NavLink
																					to={`/page/pickupControl/${item.key}`}
																					className={classes.link}
																					activeClassName={classes.active}
																				>
																					<ListItem
																						button
																						className={classes.nested}
																					>
																						<ListItemIcon
																							className={classes.iconMenu}
																						>
																							<RemoveShoppingCartIcon />
																						</ListItemIcon>
																						<ListItemText
																							primary={"Відключити самовивіз"}
																						/>
																					</ListItem>
																				</NavLink>
																			)}

																		{props.adminPermission &&
																			props.adminPermission.disableApp && (
																				<NavLink
																					to={`/page/disableapp/${item.id}`}
																					className={classes.link}
																					activeClassName={classes.active}
																				>
																					<ListItem
																						button
																						className={classes.nested}
																					>
																						<ListItemIcon
																							className={classes.iconMenu}
																						>
																							<MobileOffIcon />
																						</ListItemIcon>
																						<ListItemText
																							primary={"Відключити замовлення"}
																						/>
																					</ListItem>
																				</NavLink>
																			)}
																		{props.adminPermission &&
																			props.adminPermission?.iikoMenu && (
																				<NavLink
																					to={`/page/menu-sort/${item.id}`}
																					className={classes.link}
																					activeClassName={classes.active}
																				>
																					<ListItem
																						button
																						className={classes.nested}
																					>
																						<ListItemIcon
																							className={classes.iconMenu}
																						>
																							<AccessTime />
																						</ListItemIcon>
																						<ListItemText
																							primary={"Стоп-лист/сортировка"}
																						/>
																					</ListItem>
																				</NavLink>
																			)}
																		{props.adminPermission &&
																			props.adminPermission
																				.controlDeliveryInterval && (
																				<NavLink
																					to={`/page/load-control/${item.id}`}
																					className={classes.link}
																					activeClassName={classes.active}
																				>
																					<ListItem button>
																						<ListItemIcon
																							className={classes.iconMenu}
																						>
																							<AlarmIcon />
																						</ListItemIcon>
																						<ListItemText
																							primary={"Контроль нагрузки"}
																						/>
																					</ListItem>
																				</NavLink>
																			)}
																		{isValidPayCard && (
																			<NavLink
																				to={`/page/paycard/${item.key}`}
																				className={classes.link}
																				activeClassName={classes.active}
																			>
																				<ListItem
																					button
																					className={classes.nested}
																				>
																					<ListItemIcon
																						className={classes.iconMenu}
																					>
																						<PaymentIcon />
																					</ListItemIcon>
																					<ListItemText
																						primary={"Оплата карткою"}
																					/>
																				</ListItem>
																			</NavLink>
																		)}
																		{props.adminPermission &&
																			(props.adminPermission.saleFirstOrder ||
																				props.adminPermission.promoCodes ||
																				props.adminPermission
																					.bannerControl) && (
																				<NavLink
																					to={validPromocodesUrl(item)}
																					className={classes.link}
																					activeClassName={classes.active}
																				>
																					<ListItem
																						button
																						className={classes.nested}
																					>
																						<ListItemIcon
																							className={classes.iconMenu}
																						>
																							<LoyaltyIcon />
																						</ListItemIcon>
																						<ListItemText
																							primary={"Система лояльності"}
																						/>
																					</ListItem>
																				</NavLink>
																			)}
																	</List>
																</Collapse>
															</div>
														);
													})}
												</Collapse>
											</div>
										);
									})}
								</List>
							)}
						</Collapse>
					</>
				) : (
					<>
						<Collapse
							className={classes.collapse}
							in={props.openCategory === "settings"}
							timeout="auto"
							unmountOnExit
						>
							{props.loadCity ? (
								<div className={classes.load}>
									<Loading color="#326dff" size={24} />
								</div>
							) : (
								<List component="div">
									{props.listCity.map((item) => {
										return (
											<div key={item.key}>
												<ListItem
													key={item.key}
													button
													onClick={() => props.chooseCity(item.key)}
													className={classes.nested}
													style={{
														color: props.city === item.key ? "#326dff" : "#000",
													}}
												>
													<ListItemText primary={item.name.uk} />
													{props.city === item.key ? (
														<ExpandLess />
													) : (
														<ExpandMore />
													)}
												</ListItem>

												<Collapse
													className={classes.collapse}
													in={props.city === item.key ? true : false}
													timeout="auto"
													unmountOnExit
												>
													<List component="div" className={classes.nestedLink}>
														{props.adminPermission &&
															props.adminPermission.menu && (
																<div
																	onClick={handleMenuClick}
																	className={classes.link}
																	style={{
																		background: isMenu
																			? "rgba(50, 109, 255, .1)"
																			: "inherit",
																	}}
																>
																	<ListItem button>
																		<ListItemIcon className={classes.iconMenu}>
																			<AssignmentIcon
																				className={classes.icon}
																				style={{
																					color: isMenu ? "#326dff" : "inherit",
																					fill: isMenu ? "#326dff" : "",
																				}}
																			/>
																		</ListItemIcon>
																		<ListItemText
																			style={{
																				color: isMenu ? "#326dff" : "inherit",
																			}}
																			primary={"Стоп-лист"}
																		/>
																	</ListItem>
																</div>
															)}
														{props.adminPermission &&
															(props.adminPermission
																? props.adminPermission.productReviews ||
																  props.adminPermission.appReviews ||
																  props.adminPermission.orderReviews !==
																		undefined
																	? props.adminPermission.orderReviews
																	: true
																: true) && (
																<NavLink
																	// onClick={handleReviewsClick}
																	to={`/page/reviews/${item.key}`}
																	className={classes.link}
																	style={{
																		background: isReviews
																			? "rgba(50, 109, 255, .1)"
																			: "inherit",
																	}}
																>
																	<ListItem button>
																		<ListItemIcon className={classes.iconMenu}>
																			<CommentIcon
																				style={{
																					color: isReviews
																						? "#326dff"
																						: "inherit",
																					fill: isReviews ? "#326dff" : "",
																				}}
																			/>
																		</ListItemIcon>
																		<ListItemText
																			style={{
																				color: isReviews
																					? "#326dff"
																					: "inherit",
																			}}
																			primary={"Отзывы"}
																		/>
																	</ListItem>
																</NavLink>
															)}

														{props.adminPermission &&
															props.adminPermission.blackList && (
																<NavLink
																	// onClick={handleReviewsClick}
																	to={`/page/blacklist/${item.id}`}
																	className={classes.link}
																	style={{
																		background: isReviews
																			? "rgba(50, 109, 255, .1)"
																			: "inherit",
																	}}
																>
																	<ListItem button>
																		<ListItemIcon className={classes.iconMenu}>
																			<AssignmentIcon
																				className={classes.icon}
																				style={{
																					color: isBlackList
																						? "#326dff"
																						: "inherit",
																					fill: isBlackList ? "#326dff" : "",
																				}}
																			/>
																		</ListItemIcon>
																		<ListItemText
																			style={{
																				color: isBlackList
																					? "#326dff"
																					: "inherit",
																			}}
																			primary={"Черный список"}
																		/>
																	</ListItem>
																</NavLink>
															)}

														{props.adminPermission &&
															!!props.adminPermission.delivery && (
																<NavLink
																	to={`/page/delivery/${item.key}`}
																	className={classes.link}
																	activeClassName={classes.active}
																>
																	<ListItem button className={classes.nested}>
																		<ListItemIcon className={classes.iconMenu}>
																			<DepartureBoardIcon />
																		</ListItemIcon>
																		<ListItemText
																			primary={"Условия доставки"}
																		/>
																	</ListItem>
																</NavLink>
															)}
														{props.adminPermission &&
															props.adminPermission.workingHours && (
																<NavLink
																	to={`/page/worktime/${item.key}`}
																	className={classes.link}
																	activeClassName={classes.active}
																>
																	<ListItem button className={classes.nested}>
																		<ListItemIcon className={classes.iconMenu}>
																			<AccessTime />
																		</ListItemIcon>
																		<ListItemText primary={"Время работы"} />
																	</ListItem>
																</NavLink>
															)}
														{props.adminPermission &&
															props.adminPermission.pickupControl && (
																<NavLink
																	to={`/page/pickupControl/${item.key}`}
																	className={classes.link}
																	activeClassName={classes.active}
																>
																	<ListItem button className={classes.nested}>
																		<ListItemIcon className={classes.iconMenu}>
																			<RemoveShoppingCartIcon />
																		</ListItemIcon>
																		<ListItemText
																			primary={"Відключити самовивіз"}
																		/>
																	</ListItem>
																</NavLink>
															)}
														{props.adminPermission &&
															props.adminPermission.disableApp && (
																<NavLink
																	to={`/page/disableapp/${item.key}`}
																	className={classes.link}
																	activeClassName={classes.active}
																>
																	<ListItem button className={classes.nested}>
																		<ListItemIcon className={classes.iconMenu}>
																			<MobileOffIcon />
																		</ListItemIcon>
																		<ListItemText
																			primary={"Відключити замовлення"}
																		/>
																	</ListItem>
																</NavLink>
															)}
														{props.adminPermission &&
															props.adminPermission?.iikoMenu && (
																<NavLink
																	to={`/page/menu-sort/${item.key}`}
																	className={classes.link}
																	activeClassName={classes.active}
																>
																	<ListItem button className={classes.nested}>
																		<ListItemIcon className={classes.iconMenu}>
																			<AccessTime />
																		</ListItemIcon>
																		<ListItemText
																			primary={"Стоп-лист/сортировка"}
																		/>
																	</ListItem>
																</NavLink>
															)}
														{props.adminPermission &&
															props.adminPermission.controlDeliveryInterval && (
																<NavLink
																	to={`/page/load-control/${item.key}`}
																	className={classes.link}
																	activeClassName={classes.active}
																>
																	<ListItem button>
																		<ListItemIcon className={classes.iconMenu}>
																			<AlarmIcon />
																		</ListItemIcon>
																		<ListItemText
																			primary={"Контроль нагрузки"}
																		/>
																	</ListItem>
																</NavLink>
															)}
														{isValidPayCard && (
															<NavLink
																to={`/page/paycard/${item.key}`}
																className={classes.link}
																activeClassName={classes.active}
															>
																<ListItem button className={classes.nested}>
																	<ListItemIcon className={classes.iconMenu}>
																		<PaymentIcon />
																	</ListItemIcon>
																	<ListItemText primary={"Оплата карткою"} />
																</ListItem>
															</NavLink>
														)}
														{props.adminPermission &&
															(props.adminPermission.saleFirstOrder ||
																props.adminPermission.promoCodes ||
																props.adminPermission.bannerControl ||
																props.adminPermission.isPromotionsBanners) && (
																<NavLink
																	to={validPromocodesUrl(item)}
																	className={classes.link}
																	activeClassName={classes.active}
																>
																	<ListItem button className={classes.nested}>
																		<ListItemIcon className={classes.iconMenu}>
																			<LoyaltyIcon />
																		</ListItemIcon>
																		<ListItemText
																			primary={"Система лояльності"}
																		/>
																	</ListItem>
																</NavLink>
															)}
													</List>
												</Collapse>
											</div>
										);
									})}
								</List>
							)}
						</Collapse>
					</>
				)}

				{props.adminPermission && props.adminPermission.ourAdmin && (
					<ListItem button onClick={() => handleProductsClick()}>
						<ListItemIcon className={classes.iconMenu}>
							<FolderIcon />
						</ListItemIcon>
						<ListItemText
							className={classes.listText}
							primary="Заполнение меню"
						/>
						{props.openProducts === "products" ? (
							<ExpandLess />
						) : (
							<ExpandMore />
						)}
					</ListItem>
				)}

				{props.adminPermission && props.adminPermission.ourAdmin && (
					<Collapse
						className={classes.collapse}
						in={props.openProducts === "products"}
						timeout="auto"
						unmountOnExit
					>
						{props.preloader ? (
							<div className={classes.loader}>
								<Loading />
							</div>
						) : (
							<List component="div">
								<NavLink
									exact
									to={ROUTES.PRODUCTS}
									className={classes.link}
									activeClassName={classes.active}
								>
									<ListItem button className={classes.nested}>
										<ListItemText primary={"Категории"} />
									</ListItem>
								</NavLink>
								<NavLink
									exact
									to={ROUTES.ADD_PRODUCTS}
									className={classes.link}
									activeClassName={classes.active}
								>
									<ListItem button className={classes.nested}>
										<ListItemText primary={"Добавить товар"} />
									</ListItem>
								</NavLink>
								<NavLink
									exact
									to={ROUTES.EDIT_PRODUCTS}
									className={classes.link}
									activeClassName={classes.active}
								>
									<ListItem button className={classes.nested}>
										<ListItemText primary={"Редактировать товар"} />
									</ListItem>
								</NavLink>
								{props.adminPermission.productsOrder && (
									<NavLink
										exact
										to={ROUTES.SORT_MODE_CATEGORIES}
										className={classes.link}
										activeClassName={classes.active}
									>
										<ListItem button className={classes.nested}>
											<ListItemText primary={"Режим сортировки"} />
										</ListItem>
									</NavLink>
								)}
							</List>
						)}
					</Collapse>
				)}
			</List>
		</Drawer>
	);
};

export default Navigation;
