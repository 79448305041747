import React, {Component} from 'react';
import { Field, reduxForm } from 'redux-form';
import Button from '../../components/Button';
import CustomizedInputs from '../../components/UIform';
import Loading from '../../components/Loading';
import {required} from 'redux-form-validators';
import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';

class AlertForm extends Component {
  
    componentDidMount(){
        this.props.initialize({text: this.props.alert});
    }

    componentDidUpdate(prevProps) {
		// Проверка изменения текста
		if (prevProps.alert!== this.props.alert) {
			this.props.initialize({text: this.props.alert});
		}
	}

    render(){
        const { handleSubmit, classes } = this.props;
        return(
            <>
             <Typography variant="h6" className={classes.title}>
                    Текст повідомлення:
             </Typography>
            <form onSubmit={handleSubmit} autoComplete='off' className={classes.container}>
                <div className={classes.row}>
                <Field name="text"
                    component={CustomizedInputs}
                    type="text"
                    placeholder="Ваш текст"
                    rows={'4'}
                    multiline
                    disabled={this.props.loadStatus ? true : false}
                    validate={required({msg: "Це поле є обов'язковим для заповнення"})}/>
        
                    <Button text={this.props.loadStatus ? 
                        <Loading size={22} color={'#fff'}/> : 'Зберегти'} 
                                    disabled={this.props.loadStatus ? true : false}
                                     handleClick={handleSubmit}/>
                    </div>
            </form>
            </>
            )
    }
}
const styles = theme => ({
        title: {
      marginBottom: 10,
      marginTop: 20,
      fontSize: 18
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        display: 'flex'
    },
    row: {
        display: 'flex',
        margin: 15,
        flexWrap: 'wrap',
        width: '100%'
    }
});

export default reduxForm({
    form: 'AlertForm', 
  })(withStyles(styles, { withTheme: true })(AlertForm));