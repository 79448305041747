import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import ProductsForm from './productsForm';
import { withFirebase } from '../../../firebase';
import { withRouter } from 'react-router-dom';
import Preloader from '../preloader/preloader';
import axios from 'axios';
import { updateProduct } from '../../../reducers/shops';


const AddProduct = (props) => {
    const dispatch = useDispatch();
    const login = useSelector(state => state.auth.login);
    const defaultLanguage = useSelector(state => state.shops.defaultLanguage);
    const languages = useSelector(state => state.shops.languages);
    const cities = useSelector(state => state.shops.shops);
    const categories = useSelector(state => state.shops.categories);

    const [sortLanguages, setSortLanguages] = useState([]);
    const [citiesSpot, setCitiesSpot] = useState(null);
    const [preloader, setPreloader] = useState(false);
    const [isOpenCities, setIsOpenCities] = useState(false);

    useEffect(() => {
        getLanguages();
    }, []);

    const getLanguages = () => {
        let data = [...languages];

        if (data.length > 0 && data) {
            data = data.sort((a, b) => {
                if (a.key !== defaultLanguage) {
                    return 1;
                } else {
                    return -1;
                }
            });
        }

        setSortLanguages(data);
    }

    const handleChangeCitiesSpot = (data) => setCitiesSpot(data);
    const handleIsOpenCities = () => setIsOpenCities(!isOpenCities);

    const createProduct = (data) => {
        let languagesName = {};
        let languagesDescription = {};

        for (let index in sortLanguages) {
            let key = sortLanguages[index].key;
            let name = 'name_' + key;
            let description = 'description_' + key;

            if (data[name]) {
                languagesName[key] = data[name];
            }

            if (data[description]) {
                languagesDescription[key] = data[description];
            }
        }

        let spots = {};
        let allTerminals = [];

        cities.map(city => allTerminals.push(...city.deliveryTerminals));

        allTerminals.map((terminal, index) => {
            let spot = {
                price: "0",
                spot_id: terminal.id,
                visible: "0",
            };
            spots[index] = spot;
        });

        console.log('citiesSpot', citiesSpot);

        for (let key in spots) {
            const terminal  = spots[key];
            citiesSpot.map(({ id, visible }, index) => {
                if (Number(terminal.spot_id) === Number(id)) {

                    let spot = { 
                        price: data.firstPrice && !isOpenCities  ? String(Math.round(data.firstPrice * 100)) : String(Math.round(data.terminals[terminal.spot_id] * 100)),
                        spot_id: id,
                        visible,
                    };

                    spots[key] = spot;
                }
            });
        }

        setPreloader(true);
        let product = {
            menuCategoryId: data.categories,
            name: languagesName,
            description: languagesDescription,
            spots
        };

        return product;
    }

    const onSubmit = (data) => {
        // console.log('data', data);

        let product = createProduct(data);

        console.log('product --->>', product); 
        
        let base_url = window.location.hostname == 'localhost:3000' ? 'localhost' : window.location.hostname;

        let file = new FormData;
        file.append('photo', data.photo[0]);
        file.append('login', login);
        axios.post(`https://test.ithinkers.ru/photo/index.php`, file)
        .then(res => {
            setPreloader(false);

            const namePhoto = res.data.image_name.split(' ')[0];
            const isSuccess = res.data.check_upload_image;
            console.log('res ---- >>> ', res, isSuccess);


            product.photo = `https://test.ithinkers.ru/photo/photo/uploads/${login}/${namePhoto}`;
            product.namePhoto = namePhoto;

            if(isSuccess){
                setPreloader(true);
                props.firebase.bd.ref(`${login}/products`).push(product)
                    .then(res => {
                        setPreloader(false);
                        const productId = res.path.pieces_[1];

                        let spots = [];

                        for (let key in product.spots) {
                            const spot = product.spots[key];

                            spots.push(spot);
                        }

                        product.spots = spots;

                        let data = { id: productId, product };

                        console.log('data -->>>', data);
                        updateProduct(dispatch, data);
                        alert('Готово!');
                        // props.history.push(`/page/menu/products/edit/category${product.menuCategoryId}`);
                    })
                    .catch(error => {
                        setPreloader(false);
                        console.error(error);
                    });
      
            } else {
                alert('Ошибка, попробуйте ещё раз!')
                setPreloader(false);
            }

        }).catch(error => {
                alert('Ошибка, попробуйте ещё раз!!!')
                console.error(error);
                // setPreloader(false);
        });
    }

    return (
        <>
            <ProductsForm   onSubmit={onSubmit}
                            handleChangeCitiesSpot={handleChangeCitiesSpot}
                            handleIsOpenCities={handleIsOpenCities}
                            isOpenCities={isOpenCities}
                            cities={cities}
                            categories={categories}
                            defaultLanguage={defaultLanguage}
                            languages={sortLanguages} />
            {preloader ? <Preloader/> : null}
        </>
    );
}

export default withFirebase(withRouter(AddProduct));