import React from "react";
import TextField from "@material-ui/core/TextField";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	inputStyle: {
		[theme.breakpoints.down("600")]: {
			width: "100%",
		},
	},
	error: {
		color: "#f44336",
		fontSize: "0.75rem",
		marginTop: 3,
		textAlign: "left",
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		fontWeight: 400,
		lineHeight: "1.66",
		letterSpacing: "0.03333em",
	},
}));

export default function CustomizedInputs({ input, meta, ...props }) {
	const classes = useStyles();

	return (
		<div>
			<TextField
				className={classes.inputStyle}
				label="111"
				multiline
				rows={3}
				// variant="outlined"
				error={meta?.touched && meta.invalid}
				{...input}
				{...props}
			/>
			{meta?.touched && meta.error && (
				<div className={classes.error}>{meta.error}</div>
			)}
		</div>
	);
}
