import React, { useEffect, useState } from "react";
import Blacklist from "./index";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../../firebase";

const BlackListContainer = ({ firebase }) => {
	const [blackList, setBlackList] = useState({ phoneNumbers: [], text: "" });

	useEffect(() => {
		getBlackList();
	}, []);

	const getBlackList = () => {
		firebase.bd.ref(`web/blackList`).on("value", (snapshot) => {
			let res = snapshot.val() || { phoneNumbers: [], text: "" };

			const phoneNumbersArray = Object.keys(res?.phoneNumbers || []);

			setBlackList({
				...res,
				phoneNumbers: phoneNumbersArray,
			});
		});
	};

	const updateBlackList = (phoneNumbers, text) => {
		const data = {
			phoneNumbers: phoneNumbers.reduce((acc, number) => {
				acc[number] = true;
				return acc;
			}, {}),
			text,
		};

		firebase.bd
			.ref(`web/blackList`)
			.update(data) // Используем update, чтобы не затереть другие данные
			.then(() => {
				getBlackList();
			})
			.catch((error) => {
				console.error("Failed to update blackList:", error);
			});
	};

	return (
		<Blacklist
			numbers={blackList.phoneNumbers}
			updateBlackList={updateBlackList}
			text={blackList.text}
		/>
	);
};

export default withFirebase(withRouter(BlackListContainer));
