import React, { Component } from "react";
import { connect } from "react-redux";
import { withFirebase } from "../../../firebase";

import Categories from "./categories";

import { updateVisibility, updateBrandData } from "../../../reducers/shops";

class CategoriesContainer extends Component {
	constructor() {
		super();

		this.state = {
			adminPermission: {},
			products: [],
			hiddenProducts: [],
			hiddenCategories: [],
			preloader: false,
			isActiveCategoriesSnackbar: false,
			isActiveMenusSnackbar: false,
			cityId: null,
		};
	}

	componentDidMount() {
		this.getProducts();
		this.getDefaultHiddenCategories();
		this.getDefaultHiddenProducts();
	}

	componentDidUpdate(prevProps, prevState) {
		// Проверяем, изменился ли cityId
		let cityPath = window.location.pathname.split("/");
		cityPath = cityPath[cityPath.length - 1];

		if (cityPath !== (this.state.cityId || prevProps.cityId)) {
			this.getCityId();
			this.getDefaultHiddenCategories();
			this.getDefaultHiddenProducts();
		}
	}

	getProducts = () => {
		this.setState({ preloader: true });
		this.props.firebase.bd
			.ref(`${this.props.login}/products`)
			.once("value", (snapshot) => {
				let products = snapshot.val();

				if (products) {
					this.setState({ products, preloader: false });
				}

				this.setState({ preloader: false });
			});
	};

	getCityId = () => {
		let cityId = window.location.pathname.split("/");
		cityId = cityId[cityId.length - 1];

		this.setState({ cityId });
	};

	getDefaultHiddenCategories = () => {
		this.setState({ preloader: true });

		const adminPermission = JSON.parse(localStorage.getItem("adminPermission"));
		const chosenBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		const chosenCity = JSON.parse(localStorage.getItem("chosenCity"));

		let cityId = window.location.pathname.split("/");
		cityId = cityId[cityId.length - 1];

		if (adminPermission?.newAdmin) {
			this.props.firebase.bd
				.ref(
					`web/settings/city/${chosenCity}/brands/${chosenBrand}/category/hiddenCategories`
				)
				.once("value", (snapshot) => {
					let hiddenCategories = snapshot.val();
					this.setState({ hiddenCategories, preloader: false });
				});
		} else {
			this.props.firebase.bd
				.ref(`web/settings/city/${cityId}/category/hiddenCategories`)
				.once("value", (snapshot) => {
					let hiddenCategories = snapshot.val();
					this.setState({ hiddenCategories, preloader: false });
				});
		}
	};

	getDefaultHiddenProducts = () => {
		const adminPermission = JSON.parse(localStorage.getItem("adminPermission"));
		const chosenBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		const chosenCity = JSON.parse(localStorage.getItem("chosenCity"));

		let cityId = window.location.pathname.split("/");
		cityId = cityId[cityId.length - 1];

		if (adminPermission?.newAdmin) {
			this.props.firebase.bd
				.ref(
					`web/settings/city/${chosenCity}/brands/${chosenBrand}/category/hiddenProducts`
				)
				.once("value", (snapshot) => {
					let hiddenProducts = snapshot.val();
					this.setState({ hiddenProducts, preloader: false });
				});
		} else {
			this.props.firebase.bd
				.ref(`web/settings/city/${cityId}/category/hiddenProducts`)
				.once("value", (snapshot) => {
					let hiddenProducts = snapshot.val();
					this.setState({ hiddenProducts, preloader: false });
				});
		}
	};

	handleCategoriesSnackbar() {
		this.setState({ isActiveCategoriesSnackbar: true });

		setTimeout(() => {
			this.setState({ isActiveCategoriesSnackbar: false });
		}, 3000);
	}

	handleMenusSnackbar() {
		this.setState({ isActiveMenusSnackbar: true });

		setTimeout(() => {
			this.setState({ isActiveMenusSnackbar: false });
		}, 3000);
	}

	updateVisibility = (list, params) => {
		if (params === "hiddenCategories") {
			this.props.handleCategoriesMenu(true);
			this.setState({ hiddenCategories: list });
		} else if (params === "hiddenProducts") {
			this.props.handleProductsMenu(true);
			this.setState({ hiddenProducts: list });
		}

		this.props.updateVisibility({ list, params });

		// if (params === "hiddenCategories") {
		// 	this.props.handleCategoriesMenu(true);
		// } else {
		// 	this.props.handleProductsMenu(true);
		// }

		// console.log("list, params === >>>", list, params);

		// this.props.updateVisibility({ list, params });
	};

	changeVisibleProduct = (products) => {
		this.setState({ preloader: true });
		this.props.firebase.bd
			.ref(`${this.props.login}/products`)
			.update(products)
			.then((res) => {
				this.setState({
					preloader: false,
				});
			});
	};

	saveData = (type) => {
		const isCategories = type === "hiddenCategories" ? true : false;

		let cityId = window.location.pathname.split("/");
		cityId = cityId[cityId.length - 1];

		const adminPermission = JSON.parse(localStorage.getItem("adminPermission"));
		const currentCity = JSON.parse(localStorage.getItem("chosenCity"));
		const currentBrand = JSON.parse(localStorage.getItem("chosenBrand"));

		adminPermission?.newAdmin
			? this.props.firebase.bd
					.ref(
						`web/settings/city/${currentCity}/brands/${currentBrand}/category/${type}`
					)
					.set(this.props[type])
					.then((res) => {
						this.setState({ preloader: false });
						if (isCategories) {
							this.props.updateVisibility({
								// list: this.props.hiddenCategories,
								list: this.state.hiddenCategories,
								params: "hiddenCategories",
							});
							this.handleCategoriesSnackbar();
							this.props.handleCategoriesMenu(false);
							this.props.getDefaultHiddenCategories();
							this.props.updateBrandData({
								shopKey: currentCity,
								brandKey: currentBrand,
								path: "category.hiddenCategories",
								value: this.state.hiddenCategories,
								// value: this.props.hiddenCategories,
							});
						} else {
							this.props.updateVisibility({
								// list: this.props.hiddenProducts,
								list: this.state.hiddenProducts,
								params: "hiddenProducts",
							});
							// this.handleMenusSnackbar();
							this.props.handleProductsMenu(false);
							this.props.getDefaultHiddenProducts();
							this.props.updateBrandData({
								shopKey: currentCity,
								brandKey: currentBrand,
								path: "category.hiddenProducts",
								// value: this.props.hiddenProducts,
								value: this.state.hiddenProducts,
							});
						}
					})
			: this.props.firebase.bd
					.ref(`web/settings/city/${cityId}/category/${type}`)
					.set(this.props[type])
					.then((res) => {
						this.setState({ preloader: false });
						if (isCategories) {
							this.props.updateVisibility({
								// list: this.props.hiddenCategories,
								list: this.state.hiddenCategories,
								params: "hiddenCategories",
							});
							this.handleCategoriesSnackbar();
							this.props.handleCategoriesMenu(false);
							this.props.getDefaultHiddenCategories();
						} else {
							this.props.updateVisibility({
								// list: this.props.hiddenProducts,
								list: this.state.hiddenProducts,
								params: "hiddenProducts",
							});
							// this.handleMenusSnackbar();
							this.props.handleProductsMenu(false);
							this.props.getDefaultHiddenProducts();
						}
					});
	};

	render() {
		return (
			<>
				<Categories
					// hiddenCategories={this.props.hiddenCategories}
					// hiddenProducts={this.props.hiddenProducts}
					hiddenCategories={this.state.hiddenCategories}
					hiddenProducts={this.state.hiddenProducts}
					products={this.state.products}
					changeVisibleProduct={this.changeVisibleProduct}
					updateVisibility={this.updateVisibility}
					loadStatus={this.state.preloader}
					isActiveCategoriesSnackbar={this.state.isActiveCategoriesSnackbar}
					isActiveMenusSnackbar={this.state.isActiveMenusSnackbar}
					saveData={this.saveData}
				/>
			</>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		login: state.auth.login,
		shops: state.shops.shops,
		hiddenCategories: state.shops.hiddenCategories,
		hiddenProducts: state.shops.hiddenProducts,
	};
};

export default connect(mapStateToProps, {
	updateVisibility,
	updateBrandData,
})(withFirebase(CategoriesContainer));
