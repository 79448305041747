import React, { useEffect, useState } from "react";
import { withFirebase } from "../../firebase";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

import PickupControl from "./pickupControl";

const PickupControlContainer = (props) => {
	const cities = useSelector((state) => state.shops.shops);
	const currentCity = JSON.parse(localStorage.getItem("chosenCity"));
	const deliveryTerminals = cities[currentCity]?.deliveryTerminals;

	const [terminals, setTerminals] = useState(deliveryTerminals);

	const onSubmit = () => {
		const isValidTerminals = !!terminals.find((item) => item.isActive === true);
		if (isValidTerminals) {
			props.firebase.bd
				.ref(`/web/settings/city/${currentCity}/deliveryTerminals`)
				.update(terminals)
				.then(() => {
					alert("Готово");
				})
				.catch((error) => {
					console.error("Error updating terminals:", error);
					alert("Щось пішло не так, спробуйте ще раз");
				});
		} else {
			alert("Мінімум один термінал повинен бути активний");
		}
	};

	useEffect(() => {
		deliveryTerminals && setTerminals(deliveryTerminals);
	}, [deliveryTerminals]);

	return (
		<div>
			{terminals && (
				<PickupControl
					terminals={terminals}
					setTerminals={setTerminals}
					saveData={onSubmit}
				/>
			)}
		</div>
	);
};

export default withRouter(withFirebase(PickupControlContainer));
