/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { reduxForm } from "redux-form";
import axios from "axios";
import ImageUploading from "react-images-uploading";

import Button from "../../../../components/Button";

import { InputField } from "../../../../components/UIform/inputField";
import CustomizedInputs from "../../../../components/UIform";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";

import ModalComponent from "../../../../components/ModalComponent";
import CustomInputTextarea from "../../../../components/UIform/inputTextarea";

let AddBannerModalForm = (props) => {
	const classes = useStyles();
	const { handleSubmit, handleClose, images, setImages } = props;

	const [openModal, setOpenModal] = useState(false);
	const [isSubmit, setIsSubmit] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [checked, setChecked] = useState(null);

	// загрузка банеров
	const [open, setOpen] = useState(true);
	const [data, setData] = useState({
		available: true,
		photo: "",
		// smallPhoto: "",
		title: "",
		description: "",
		// productsId: [""],
	});

	// Состояние для отображения ошибок Snackbar
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [snackBarMessage, setSnackBarMessage] = useState("");

	const maxNumber = 999;
	const maxFileSize = 512000;
	const maxAcceptType = ["jpg", "jpeg", "png"];

	const handleSnackBarClose = () => {
		setOpen(false);
		setTimeout(() => setOpen(true), 6100);
	};

	function Alert(props) {
		return <MuiAlert elevation={6} variant="filled" {...props} />;
	}

	const SnackBarComponent = ({ errorMessage }) => (
		<Snackbar open={open} autoHideDuration={6000} onClose={handleSnackBarClose}>
			<Alert onClose={handleSnackBarClose} severity="error">
				{errorMessage}
			</Alert>
		</Snackbar>
	);

	const handleCloseModal = () => {
		setOpenModal(false);
		setIsSubmit(false);
	};

	const handleClickSubmit = () => {
		setOpenModal(false);
		setIsSubmit(true);

		const isUploadLargePhoto = data.photo.length > 0;
		const isUploadSmallPhoto = data.smallPhoto?.length > 0;

		// if (isUploadLargePhoto && isUploadSmallPhoto) {
		if (isUploadLargePhoto) {
			handleSubmit(data);
			handleClose();
		} else {
			setSnackBarMessage("Обязательно загрузите  баннер");
			setOpenSnackBar(true);
		}
	};

	const submitForm = () => {
		const { valid } = props;
		if (valid) {
			setOpenModal(true);
			setIsLoading(true);
		}
	};

	const handleInputFieldChange = (e) => {
		const { name, value } = e.target;

		console.log("value", value);
		setData({
			...data,
			[name]: value,
		});
	};

	const handleLargePhotoInputChange = async (imageList) => {
		const file = imageList[0].file;
		if (file) {
			setData({
				...data,
				photo: file.name, // Обновляем имя файла для largePhoto
			});
			const login = localStorage.getItem("@login");
			try {
				const formData = new FormData();
				formData.append("photo", file);
				formData.append("login", login);
				formData.append("folder", "loyalty-system");

				const response = await axios.post(
					"https://e-admin.com.ua/photo/index.php",
					formData
				);

				if (response && response.data && response.data.image_name) {
					const namePhoto = response.data.image_name.split(" ")[0];
					if (namePhoto && login) {
						setData((prevData) => ({
							...prevData,
							photo: `https://e-admin.com.ua/photo/photo/uploads/${login}/loyalty-system/${namePhoto}`,
						}));
					} else {
						throw new Error("Неверные данные логина или названия изображения");
					}
				}
			} catch (error) {
				setSnackBarMessage("Ошибка загрузки изображения");
				setOpenSnackBar(true);
			}
		}
	};

	// const handleSmallPhotoInputChange = async (imageList) => {
	// 	const file = imageList[0].file;
	// 	if (file) {
	// 		setData({
	// 			...data,
	// 			smallPhoto: file.name, // Обновляем имя файла для largePhoto
	// 		});
	// 		const login = localStorage.getItem("@login");
	// 		try {
	// 			const formData = new FormData();
	// 			formData.append("photo", file);
	// 			formData.append("login", login);
	// 			formData.append("folder", "loyalty-system");

	// 			const response = await axios.post(
	// 				"https://e-admin.com.ua/photo/index.php",
	// 				formData
	// 			);

	// 			if (response && response.data && response.data.image_name) {
	// 				const namePhoto = response.data.image_name.split(" ")[0];
	// 				if (namePhoto && login) {
	// 					setData((prevData) => ({
	// 						...prevData,
	// 						smallPhoto: `https://e-admin.com.ua/photo/photo/uploads/${login}/loyalty-system/${namePhoto}`,
	// 					}));
	// 				} else {
	// 					throw new Error("Неверные данные логина или названия изображения");
	// 				}
	// 			}
	// 		} catch (error) {
	// 			setSnackBarMessage("Ошибка загрузки изображения");
	// 			setOpenSnackBar(true);
	// 		}
	// 	}
	// };

	console.log("banner data", data);

	return (
		<>
			<ModalComponent
				title="Подтвердите действие!"
				description="Вы подтверждаете сохранение изменений?"
				open={openModal}
				handleClose={handleCloseModal}
				handleClickSubmit={handleClickSubmit}
			/>
			<form onSubmit={handleSubmit} autoComplete="off">
				<div className={classes.otherFields}>
					<InputField
						title="Название"
						placeholder="Введите название баннера"
						className={classes.otherInput}
						component={CustomizedInputs}
						name="title"
						type="text"
						onChange={handleInputFieldChange}
					/>
					<InputField
						title="Описание"
						component={CustomInputTextarea}
						className={classes.textarea}
						placeholder="Введите описание баннера"
						name="description"
						onChange={handleInputFieldChange}
					/>
					<div className={classes.imageUploading}>
						<ImageUploading
							value={images}
							onChange={handleLargePhotoInputChange}
							maxNumber={maxNumber}
							maxFileSize={maxFileSize}
							acceptType={maxAcceptType}
							dataURLKey="data_url"
						>
							{({ onImageUpload, errors }) => (
								// write your building UI
								<div className={classes.buttonsWrap}>
									<Button
										className={classes.addBanner}
										handleClick={onImageUpload}
										load={false}
										text={"Добавить баннер"}
									/>
									<img
										src={data.photo}
										alt="Выберите изображение"
										className={classes.selectedImage}
									/>
									{errors && (
										<div>
											<SnackBarComponent
												errorMessage={
													errors.maxNumber
														? "Выбранное изображение имеет слишком большое название"
														: errors.acceptType
														? "Поддерживаемые разширения изображений jpg, jpeg, png"
														: errors.maxFileSize
														? "Размер фото не более 0.5 МБ"
														: errors.resolution
														? "Максимальное расширение изображения 1920px x 1280px"
														: ""
												}
											/>
										</div>
									)}
								</div>
							)}
						</ImageUploading>
						<Typography
							variant="h6"
							component="h3"
							className={classes.warningMessage}
						>
							Рекомендуемый размер фото для баннера 690x862 (расширение файла
							jpg, jpeg, png)
						</Typography>
					</div>
					{/* <div className={classes.imageUploading}>           
                                    <ImageUploading
                                        value={images}
                                        onChange={handleSmallPhotoInputChange}
                                        maxNumber={maxNumber}
                                        maxFileSize={maxFileSize}
                                        acceptType={maxAcceptType}
                                        dataURLKey="data_url"
                                    >
                                        {({
                                        onImageUpload,
                                        errors
                                        }) => (
                                        // write your building UI
                                        <div className={classes.buttonsWrap}>
                                                <Button
                                                    className={classes.addBanner}
                                                    handleClick={onImageUpload}    
                                                    load={false}
                                                    text={'Добавить маленький баннер'}
                                                />
                                                <img src={data.smallPhoto} alt="Выберите изображение" className={classes.selectedImage}/>                       
                                            {errors && (
                                                <div>
                                                    <SnackBarComponent errorMessage={
                                                    errors.maxNumber ? 'Выбранное изображение имеет слишком большое название' :
                                                    errors.acceptType ? 'Поддерживаемые разширения изображений jpg, jpeg, png' :
                                                    errors.maxFileSize ? 'Размер фото не более 0.5 МБ' :
                                                    errors.resolution ? 'Максимальное расширение изображения 1920px x 1280px' : ''
                                                    } />
                                                </div>
                                            )}
                                        </div>
                                        )}
                                    </ImageUploading>
                                    <Typography variant="h6" component="h2" className={classes.warningMessage}>
                                        Рекомендуемый размер фото для баннера 540x300 (расширение файла jpg, jpeg, png)
                                    </Typography>
                                </div> */}
				</div>

				<div className={classes.buttonWrapper}>
					<Button
						fontSize={22}
						color={"#fff"}
						className={classes.button}
						text="Сохранить"
						handleClick={() => submitForm()}
					/>
				</div>
				{openSnackBar && (
					<SnackBarComponent
						errorMessage={snackBarMessage}
						onClose={handleSnackBarClose}
					/>
				)}
			</form>
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	dialogPaper: {
		width: "80%",
		maxWidth: 1057,
		padding: 24,
	},
	dialogTitle: {
		padding: 0,
		"& h2": {
			fontSize: 16,
			fontWeight: "normal",
			lineHeight: "25px",
		},
	},
	iconsWrapper: {
		display: "flex",
	},
	closeIcon: {
		marginRight: 16,
		color: "#476282",
		"&:hover": {
			color: "black",
			cursor: "pointer",
		},
	},
	buttonWrapper: {
		margin: "0 auto",
		marginTop: 30,
		textAlign: "center",
	},
	discountRadios: {
		marginTop: 30,
		[theme.breakpoints.down("600")]: {
			marginTop: 16,
		},
	},
	discountInput: {
		display: "flex",
		flexDirection: "column",
		height: 57,
		width: "100%",
		padding: "0 16px 16px 16px",
		marginBottom: 16,
		boxSizing: "border-box",

		boxShadow: "0px 14px 23px rgba(232, 228, 228, 0.5)",
		background: "white",
		borderRadius: 5,
	},
	discountTitle: {
		fontSize: 16,
		fontWeight: 500,
		lineHeight: "57px",
		color: "black",
	},
	discountInputWrap: {
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		cursor: "pointer",
	},
	discountRadio: {
		margin: 0,
		cursor: "pointer",
	},
	discountHidden: {
		width: 328,
		[theme.breakpoints.down("600")]: {
			width: "100%",
		},
	},
	otherFields: {},
	code: {},
	otherInput: {
		width: "100%",
		marginTop: 8,
		height: 45,
		"& input": {
			width: "100%",
			height: 45,
			border: "1px solid #DADCE0",
			borderRadius: "5px",
			padding: "11px 50px 11px 16px",
			boxSizing: "border-box",
		},
		"& .MuiInput-underline:hover:not(.Mui-disabled):before": {
			content: "none",
		},
		"& .MuiInput-underline:after": {
			content: "none",
		},
		"& .MuiInput-underline:before": {
			content: "none",
		},
		"& .Mui-error input": {
			border: "1px solid red",
		},
	},
	textarea: {
		// maxWidth: '327px',
		width: "100%",
		[theme.breakpoints.down("600")]: {
			maxWidth: "100%",
		},
		"& > div:before": {
			display: "none",
		},
		"& > div": {
			// width: '327px',
			marginTop: 8,
			resize: "none",
			// height: 103,
			border: "1px solid #DADCE0",
			borderRadius: "5px",
			padding: "11px 16px 11px 16px",
			boxSizing: "border-box",
			fontSize: 16,
			fontFamily: "Roboto, Helvetica, Arial, sans-serif",
			lineHeight: "24px",
			[theme.breakpoints.down("600")]: {
				width: "100%",
			},
		},
		"& > div:after": {
			display: "none",
		},
		"& > div.Mui-error": {
			border: "1px solid red;",
		},
		"&::-webkit-input-placeholder": {
			color: "#DADCE0",
			fontWeight: 400,
		},
		"&::textarea:-moz-placeholder": {
			color: "#DADCE0",
			fontWeight: 400,
		},
		"&::textarea::-moz-placeholder": {
			color: "#DADCE0",
			fontWeight: 400,
		},
		"&::textarea:-ms-input-placeholder": {
			color: "#DADCE0",
			fontWeight: 400,
		},
		"&::textarea::placeholder": {
			color: "#DADCE0",
			fontWeight: 400,
		},
	},
	otherInputDescription: {
		width: "100%",
		marginTop: 8,
		"& input": {
			width: "100%",
			height: 103,
			border: "1px solid #DADCE0",
			borderRadius: "5px",
			padding: "11px 50px 11px 16px",
			boxSizing: "border-box",
		},
		"& .MuiInput-underline:hover:not(.Mui-disabled):before": {
			content: "none",
		},
		"& .MuiInput-underline:after": {
			content: "none",
		},
		"& .MuiInput-underline:before": {
			content: "none",
		},
		"& .Mui-error input": {
			border: "1px solid red",
		},
	},
	checkbox: {
		"&.MuiCheckbox-colorPrimary.Mui-checked": {
			color: "#326dff",
		},
		"&.MuiCheckbox-colorPrimary": {
			padding: 10,
		},
	},
	warningMessage: {
		marginBottom: 16,
		padding: "0 16px",
		fontSize: 16,
		fontWeight: "normal",
		lineHeight: "20px",
		color: "#326dff",
		boxSizing: "border-box",
		[theme.breakpoints.down("md")]: {
			paddingTop: 16,
			fontSize: 14,
			textAlign: "center",
		},
	},
	buttonsWrap: {
		display: "flex",
		justifyContent: "center",
	},
	addBanner: {
		marginRight: 24,
	},
	selectedImage: {
		width: 105,
		height: 48,
		margin: 10,
	},
	imageUploading: {
		marginTop: 10,
	},
}));

AddBannerModalForm = reduxForm({
	form: "CreateBannerForm",
	enableReinitialize: true,
})(AddBannerModalForm);

export default AddBannerModalForm;
