import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../../firebase';
import DeliveryForm from './deliveryForms';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';
import Loading from '../../components/Loading';

import {getInitialValues} from './other';

const Title = ({ classes }) => 
  <FormLabel className={classes.formLegend} component="legend">
    <Typography className={classes.title} variant="h3">Условие доставки</Typography>
  </FormLabel>

const Wrapper = ({ children, classes }) =>
  <Grid container > 
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          {children}
        </Paper>
      </Grid>
  </Grid>

const Form = ({ props, deliveryZone }) => {
  const {delivery, shops, saveZones, editZone, deleteZone, saveCity, preloader} = props;

  return shops 
          ? <DeliveryForm 
              saveZones={saveZones} 
              saveCity={saveCity} 
              editZone={editZone}
              deleteZone={deleteZone}
              preloader={preloader}
              delivery={delivery}
              deliveryZone={deliveryZone}
              shops={shops}
              initialValues={getInitialValues(props)}  /> 
          : <Loading size={22} color={'#326dff'}/>
}

const WorkTime = (props) => {
    const classes = useStyles();
    const {shops} = props;

    let addressName; 
    let deliveryZone;

    if (shops && shops.deliveryZone && shops.address) {
      addressName = shops.address;
      deliveryZone = shops.deliveryZone;
    }

    return(
      <Wrapper classes={classes}>
        <Title classes={classes} addressName={addressName} />
        <Form props={props} deliveryZone={deliveryZone} />
      </Wrapper>
    )
} 

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    paddingTop: 24,
    paddingBottom: 16,
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: 22,
    lineHeight: '26px',
    fontWeight: 'bold',
    letterSpacing: '0.003em',
    color: 'black'
  },
  arrowIcon: {
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formLegend: {
    display: 'flex',
  },
}))

export default withFirebase(WorkTime);