import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withFirebase } from "../../../firebase";

import Promocodes from "./index";

class PromocodesContainer extends Component {
	constructor() {
		super();

		this.state = {
			preloader: false,
			promocodes: null,
			keyShop: null,
			currentCity: null,
			currentBrand: null,
			isNewAdmin: false,
		};
	}

	componentDidMount() {
		this.setState({
			keyShop: this.props.match.url,
		});

		this.getPromocodes();
		const adminPermission = JSON.parse(localStorage.getItem("adminPermission"));
		const isNewAdmin = adminPermission?.newAdmin;

		this.setState({
			isNewAdmin: isNewAdmin,
		});
	}

	componentDidUpdate(prevProps, prevState) {
		// Проверяем, изменилось ли значение isNewAdmin
		if (prevState.isNewAdmin !== this.state.isNewAdmin) {
			this.getPromocodes();
		}
	}

	getPromocodes = () => {
		let url = this.props.match.url.split("/");
		url = url.find((item) => item === "0" || parseInt(item));

		const currentCity = JSON.parse(localStorage.getItem("chosenCity"));
		const currentBrand = JSON.parse(localStorage.getItem("chosenBrand"));

		if (this.state.isNewAdmin) {
			this.props.firebase.bd
				.ref(
					`web/settings/city/${currentCity}/brands/${currentBrand}/loyaltySettings/promocodes`
				)
				.on("value", (snapshot) => {
					let promocodes = snapshot.val();

					this.setState({
						promocodes,
						keyShop: url,
						currentCity,
						currentBrand,
					});
				});
		} else {
			this.props.firebase.bd
				.ref(`web/settings/city/${url}/loyaltySettings/promocodes`)
				.on("value", (snapshot) => {
					let promocodes = snapshot.val();

					this.setState({
						promocodes,
						keyShop: url,
						currentCity,
						currentBrand,
					});
				});
		}
	};

	changeChecker = (value, id) => {
		console.log("id (changeChecker) --- >", id);
		this.setState({ preloader: true });

		if (this.state.isNewAdmin) {
			this.props.firebase.bd
				.ref(
					`web/settings/city/${this.state.currentCity}/brands/${this.state.currentBrand}/loyaltySettings/promocodes/${id}`
				)
				.update({ isAvailable: value })
				.then((res) => {
					this.setState({ preloader: false });
				});
		} else {
			this.props.firebase.bd
				.ref(
					`web/settings/city/${this.state.keyShop}/loyaltySettings/promocodes/${id}`
				)
				.update({ isAvailable: value })
				.then((res) => {
					this.setState({ preloader: false });
				});
		}
	};

	deletePromocode = (id) => {
		console.log("=== Delete promocode ===", id);
		this.setState({ preloader: true });

		if (this.state.isNewAdmin) {
			this.props.firebase.bd
				.ref(
					`web/settings/city/${this.state.currentCity}/brands/${this.state.currentBrand}/loyaltySettings/promocodes/`
				)
				.child(id)
				.remove()
				.then((res) => {
					this.setState({ preloader: false });
				});
		} else {
			this.props.firebase.bd
				.ref(
					`web/settings/city/${this.state.keyShop}/loyaltySettings/promocodes/`
				)
				.child(id)
				.remove()
				.then((res) => {
					this.setState({ preloader: false });
				});
		}
	};

	sendData = (data) => {
		console.log("=== Submit data (promocodes) ===", data);

		this.setState({ preloader: true });
		if (this.state.isNewAdmin) {
			this.props.firebase.bd
				.ref(
					`web/settings/city/${this.state.currentCity}/brands/${this.state.currentBrand}/loyaltySettings/promocodes/`
				)
				.set(data)
				.then((res) => {
					this.setState({ preloader: false });
					this.getPromocodes();
				});
		} else {
			this.props.firebase.bd
				.ref(
					`web/settings/city/${this.state.keyShop}/loyaltySettings/promocodes/`
				)
				.set(data)
				.then((res) => {
					this.setState({ preloader: false });
					this.getPromocodes();
				});
		}
	};

	render() {
		return (
			<>
				<Promocodes
					onSubmit={this.sendData}
					loadStatus={this.state.preloader}
					promocodes={this.state.promocodes}
					changeChecker={this.changeChecker}
					deletePromocode={this.deletePromocode}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		login: state.auth.login,
		shops: state.shops.shops,
		appSettings: state.shops.appSettings,
	};
};

export default connect(
	mapStateToProps,
	null
)(withRouter(withFirebase(PromocodesContainer)));
