import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withFirebase } from '../../../../firebase';
import { updateDiscount } from '../../../../reducers/shops';

import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';

import Switch from '../../../../components/Switch';
import ModalComponent from './ModalForm';
import {useStyles} from './styles';

const DeliveryOrder = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const name = 'deliveryDiscount';
    const deliveryDiscount = useSelector(state => state.shops.appSettings.deliveryDiscount);
    const login = useSelector(state => state.auth.login);
    const isDeliveryDiscount = deliveryDiscount !== "0" && deliveryDiscount !== undefined;
    const initialValues = { deliveryDiscount };

    const [openModal, setOpenModal] = useState(false);
    const [preloader, setPreloader] = useState(false);

    const handleClose = () => setOpenModal(false);
    const handleClickSubmit = () => setOpenModal(false);
    const handleClick = () => !openModal ? setOpenModal(true) : null;

    const changeFirebase = (data) => {
        setPreloader(true);
        props.firebase.bd
            .ref(`${login}/settings/appSettings`)
            .update(data)
            .then(res => {
                const updateData = { name, value: data.deliveryDiscount };

                updateDiscount(dispatch, updateData);
                setPreloader(false)
            })
            .catch(err => {
                console.error(err);
            });
    }

    const changeChecker = (value) => {
        let data = {};

        !value 
            ? data.deliveryDiscount = "0" 
            : data.deliveyDiscount = deliveryDiscount;

        const updateData = { 
            name, 
            value: data.deliveryDiscount 
        };

        updateDiscount(dispatch, updateData);
        changeFirebase(data);
    }

    const onSubmit = (data) => {
        // console.log('=== Submit data ===', data);
        changeFirebase(data);
    }

    return (
        <div className={classes.order}>
            <div className={classes.orderContent}>
                <ModalComponent
                    load={preloader}
                    title="На доставку"
                    initialValues={initialValues}
                    open={openModal}
                    onSubmit={onSubmit} 
                    handleClose={handleClose} 
                    handleClickSubmit={handleClickSubmit} />
                <Typography className={classes.title}>На доставку</Typography>
                <div className={classes.orderChange}>
                    <EditIcon className={classes.editIcon} onClick={() => handleClick()} />
                    <Switch 
                        disabled={!isDeliveryDiscount} 
                        className={classes.switchComponent} 
                        active={isDeliveryDiscount}
                        changeChecker={changeChecker} />
                </div>
            </div>
        </div>
    )
}

export default withFirebase(DeliveryOrder);