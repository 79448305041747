export const DEFAULT = "/page/";
export const SIGN_IN = "/signin";
export const PUSH = "/page/push";
export const PICKUPCONTROL = "/page/pickupControl/:city";
export const DISABLEAPP = "/page/disableapp/:city";
// export const REVIEWS = "/page/reviews";
export const REVIEWS = "/page/reviews/:brandId";
export const BLACKLIST = "/page/blacklist/:brandId";

export const LOAD_CONTROL = "/page/load-control/:city";
export const PAY = "/page/paycard/:city";
//export const CATEGORIES = '/page/menu/categories';
export const WORKTIME = "/page/worktime/:city";
export const DELIVERY = "/page/delivery/:city";
export const CATEGORIES = "/page/menu/categories/:city";
export const PRODUCTS = "/page/menu/products";
export const PROMOTION = "/page/promotion";
export const ADD_CATEGORY = "/page/menu/products/addCategory";
export const EDIT_CATEGORY = "/page/menu/products/editCategory/:id";
export const ADD_PRODUCTS = "/page/menu/products/add";
export const EDIT_PRODUCTS = "/page/menu/products/edit";
export const SORT_MODE = "/page/menu/products/sort-mode";
export const SORT_MODE_PRODUCTS = "/page/menu/products/sort-mode/products";
export const SORT_MODE_CATEGORIES = "/page/menu/products/sort-mode/categories";
export const SORT_MODE_PRODUCT =
	"/page/menu/products/sort-mode/products/category:id";
export const SORT_MODE_CATEGORY =
	"/page/menu/products/sort-mode/categories/:id";
export const EDIT_PRODUCTS_ID = "/page/menu/products/edit/:id";
export const EDIT_CATEGORIES_ID = "/page/menu/products/edit/category:id";
export const DISCOUNTS = "/page/discounts/:city";
export const MENU_SORT = "/page/menu-sort/:brandId";
export const CATEGORIES_SORT = "/page/menu-sort/:brandId/categories";
export const PRODUCTS_SORT = "/page/menu-sort/:brandId/products/category/:id";
export const CATEGORY_SORT = "/page/menu-sort/:brandId/products/category/:id";
