/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Field, reduxForm } from "redux-form";
import {
	required,
	numericality,
	addValidator,
	format,
} from "redux-form-validators";

import Button from "../../../components/Button";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { InputField } from "../../../components/UIform/inputField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ModalComponent from "../../../components/ModalComponent";
import CustomizedInputs from "../../../components/UIform";
import Loading from "../../../components/Loading";
import Chip from "@material-ui/core/Chip";
import Popover from "../../../components/Popover";

import CustomInputTextarea from "../../../components/UIform/inputTextarea";

import CalendarPeriod from "./CalendarPeriod";

const promocodesValidator = addValidator({
	defaultMessage: "Тільки латинські літери",
	validator: function (options, value, allValues) {
		return (options.lowerCase ? /^[a-z0-9A-Z]+$/ : /^[a-z]+$/i).test(value);
	},
});

let CreateModalPromocodesForm = (props) => {
	const classes = useStyles();
	const isMin600 = useMediaQuery("(max-width: 599px)");

	const [openModal, setOpenModal] = useState(false);
	const [isSubmit, setIsSubmit] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [data, setData] = useState(null);
	const [checked, setChecked] = useState(null);
	const { handleSubmit } = props;

	const [noPromocodeForProductsIds, setNoPromocodeForProductsIds] = useState(
		[]
	);
	const [noPromocodeForCategoriesIds, setNoPromocodeForCategoriesIds] =
		useState([]);
	const [idProducts, setIdProducts] = useState("");
	const [idCategories, setIdCategories] = useState("");

	const [count, setCount] = useState(1);

	const isPresent = count === 1;
	const isDiscount = count === 2;
	const isAmount = count === 3;

	// console.log('isPresent, isDiscount, isAmount', isPresent, isDiscount, isAmount);
	// console.log('count', count);
	// console.log('dataForm', props.dataForm);

	const handleChange = (e) => {
		const name = e.target.name;

		console.log("target", e.target);

		if (name == "radioPresent" && count !== 1) {
			setCount(1);
		}

		if (name == "radioDiscount" && count !== 2) {
			setCount(2);
		}

		if (name == "radioAmount" && count !== 3) {
			setCount(3);
		}
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;

		if (name === "idProducts") {
			setIdProducts(value); // Обновляем локальное состояние при изменении
			return;
		}

		if (name === "idCategories") {
			setIdCategories(value);
			return;
		}

		setData({
			...data,
			parametr: value,
		});
	};

	const handleAddProduct = () => {
		const status = noPromocodeForProductsIds.find(
			(chip) => chip === idProducts
		);
		const isValue = idProducts.length > 0 ? true : false;

		if (status === undefined && isValue) {
			setNoPromocodeForProductsIds([...noPromocodeForProductsIds, idProducts]);
			setIdProducts("");
			props.change("idProducts", "");
		} else if (!isValue) {
			alert("Пустое поле");
		} else {
			alert("Данный ID уже есть в списке");
		}
	};

	const handleDeleteProduct = (chipToDelete) => () => {
		const list = noPromocodeForProductsIds.filter(
			(chip) => chip !== chipToDelete
		);
		setNoPromocodeForProductsIds(list);
	};

	const handleAddCategory = () => {
		const status = noPromocodeForCategoriesIds.find(
			(chip) => chip === idCategories
		);
		const isValue = idCategories.length > 0 ? true : false;

		if (status === undefined && isValue) {
			setNoPromocodeForCategoriesIds([
				...noPromocodeForCategoriesIds,
				idCategories,
			]);
			setIdCategories("");
			props.change("idCategories", "");
		} else if (!isValue) {
			alert("Пустое поле");
		} else {
			alert("Данный ID уже есть в списке");
		}
	};

	const handleDeleteCategory = (chipToDelete) => () => {
		const list = noPromocodeForCategoriesIds.filter(
			(chip) => chip !== chipToDelete
		);
		setNoPromocodeForCategoriesIds(list);
	};

	useEffect(() => {
		if (!checked) {
			if (data) {
				setChecked(data.useDiscountIds);
			}
		}
	}, [checked, data]);

	useEffect(() => {
		if (isLoading && isSubmit && !openModal) {
			const { onSubmit, handleClose, dataForm } = props;
			let arr = [];

			for (let key in dataForm) {
				arr.push(key);
			}

			// let newId = arr.length !== 0 ? +arr[arr.length - 1] + 1 : 0;
			let newId = data.id.replace(/ /g, "");

			console.log("newId", newId);

			onSubmit({
				...dataForm,
				[newId]: {
					...data,
					discountId: "",
					useDiscountIds: checked ? checked : false,
					noPromocodeForCategoriesIds:
						noPromocodeForCategoriesIds.length > 0
							? ["", ...noPromocodeForCategoriesIds]
							: [""],
					noPromocodeForProductsIds:
						noPromocodeForProductsIds.length > 0
							? noPromocodeForProductsIds
							: ["", ...noPromocodeForProductsIds],
				},
			});

			handleClose();
		}

		if (isLoading && !isSubmit && !openModal) {
			console.log("Вы отменили");
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmit, isLoading, openModal]);

	const handleCloseModal = () => {
		setOpenModal(false);
		setIsSubmit(false);
	};

	const handleClickSubmit = () => {
		setOpenModal(false);
		setIsSubmit(true);
	};

	const submitForm = () => {
		const { valid } = props;

		if (valid) {
			setOpenModal(true);
			setIsLoading(true);
		}
	};

	const handleCheckboxChange = (e) => {
		const value = e.target.checked;

		if (checked !== value) {
			setChecked(value);
		}
	};

	const handleInputFieldChange = (e) => {
		const { name, value } = e.target;

		const processedValue = name === "orderLimit" ? Number(value) : value;

		setData({
			...data,
			[name]: processedValue,
			type: count,
		});
	};

	if (!data) {
		setData({
			...data,
			isAvailable: true,
			type: count,
		});
	}

	// console.log('dataForm', props.dataForm);
	// console.log('data', data);

	return (
		<>
			<ModalComponent
				title="Підтвердіть дію!"
				description="Ви підтверджуєте збереження змін?"
				open={openModal}
				handleClose={handleCloseModal}
				handleClickSubmit={handleClickSubmit}
			/>
			<form onSubmit={handleSubmit} autoComplete="off">
				<div className={classes.discountRadios}>
					<div
						className={classes.discountInput}
						style={{ height: isPresent ? "100%" : "57px" }}
					>
						<label htmlFor="radioPresent" className={classes.discountInputWrap}>
							<Typography className={classes.discountTitle}>
								Подарунок
							</Typography>
							<Field
								id="radioPresent"
								className={classes.discountRadio}
								name="radioPresent"
								component="input"
								type="radio"
								value={isPresent ? "radioPresent" : ""}
								checked={isPresent}
								onChange={handleChange}
							/>
						</label>
						{isPresent && (
							<div className={classes.discountHidden}>
								<InputField
									title="ID Товару"
									placeholder="Введіть ID товару"
									name="present"
									type="text"
									onChange={handleInputChange}
									validate={[required({ msg: "Це обовʼязкове поле" })]}
								/>
							</div>
						)}
					</div>
					<div
						className={classes.discountInput}
						style={{ height: isDiscount ? "100%" : "57px" }}
					>
						<label
							htmlFor="radioDiscount"
							className={classes.discountInputWrap}
						>
							<Typography className={classes.discountTitle}>Знижка</Typography>
							<Field
								id="radioDiscount"
								className={classes.discountRadio}
								name="radioDiscount"
								component="input"
								type="radio"
								value={isDiscount ? "radioDiscount" : ""}
								checked={isDiscount}
								onChange={handleChange}
							/>
						</label>
						{isDiscount && (
							<div className={classes.discountHidden}>
								<InputField
									title="Відсоток знижки"
									placeholder="Введіть відсоток знижки"
									name="discount"
									onChange={handleInputChange}
									validate={[
										required({ msg: "Це обовʼязкове поле" }),
										numericality({
											int: true,
											"<=": 100,
											msg: {
												"<=": "Значення повинно бути менше або дорівнювати {count}",
												int: "Лише цілі числа",
											},
										}),
									]}
								/>
							</div>
						)}
					</div>
					<div
						className={classes.discountInput}
						style={{ height: isAmount ? "100%" : "57px" }}
					>
						<label htmlFor="radioAmount" className={classes.discountInputWrap}>
							<Typography className={classes.discountTitle}>Сума</Typography>
							<Field
								id="radioAmount"
								className={classes.discountRadio}
								name="radioAmount"
								component="input"
								type="radio"
								value={isAmount ? "radioAmount" : ""}
								checked={isAmount}
								onChange={handleChange}
							/>
						</label>
						{isAmount && (
							<div className={classes.discountHidden}>
								<InputField
									title="Сума знижки"
									placeholder="Введіть суму знижки"
									name="amount"
									onChange={handleInputChange}
									validate={[
										required({ msg: "Це обовʼязкове поле" }),
										numericality({
											int: true,
											msg: {
												int: "Лише цілі числа",
											},
										}),
									]}
								/>
							</div>
						)}
					</div>
					<div className={classes.otherFields}>
						<div className={classes.code}>
							<CalendarPeriod data={data} setData={setData} />
							<InputField
								title="Код промокоду"
								placeholder="Введіть код промоакції"
								className={classes.otherInput}
								name="id"
								type="text"
								onChange={handleInputFieldChange}
								validate={[
									required({ msg: "Це обовʼязкове поле" }),
									format({ with: /^[a-z0-9A-Z]+$/, msg: "Некоректні дані" }),
								]}
							/>
							<InputField
								title="Назва"
								placeholder="Введіть назву промоакції"
								className={classes.otherInput}
								name="title"
								type="text"
								onChange={handleInputFieldChange}
								validMsg="Це обовʼязкове поле"
							/>
							<InputField
								title="Опис"
								component={CustomInputTextarea}
								className={classes.textarea}
								placeholder="Введіть опис промоакції"
								name="description"
								onChange={handleInputFieldChange}
								validMsg="Це обовʼязкове поле"
							/>
							<div className={classes.orderLimitWrapper}>
								<InputField
									title="Мнімальна сума замовлення"
									placeholder="Введіть мнімальну суму або 0"
									className={classes.otherInput}
									name="orderLimit"
									type="number"
									onChange={handleInputFieldChange}
									validate={[
										required({ msg: "Це обовʼязкове поле" }),
										numericality({
											int: true,
											msg: {
												int: "Лише цілі числа",
											},
										}),
									]}
								/>
								<Popover
									className={classes.orderLimitPopover}
									title=""
									hiddenTitle="Мінімальна сума замовлення для використання промокоду, якщо така відсутня вкажіть 0"
								/>
							</div>
						</div>
						<FormControlLabel
							control={
								<Checkbox
									className={classes.checkbox}
									checked={checked}
									onChange={handleCheckboxChange}
									name="checkedA"
									color="primary"
								/>
							}
							label="Застосовувати для акційних товарів"
						/>
					</div>
				</div>
				<div className={classes.categoriesWrap}>
					<div className={classes.headerWrapper}>
						<Typography className={classes.typography}>
							Категорії, на які не застосовується промокод
						</Typography>
						<Popover
							className={classes.popover}
							title=""
							hiddenTitle="Список категорій на які не застосовується промокод"
						/>
					</div>
					<div className={classes.chips}>
						<ul className={classes.chipsList}>
							{noPromocodeForCategoriesIds.map((data, index) => {
								return (
									<li key={index} className={classes.chipsListLi}>
										<Chip
											label={data}
											onDelete={handleDeleteCategory(data)}
											className={classes.chip}
										/>
										{isMin600 && <hr className={classes.hr} />}
									</li>
								);
							})}
						</ul>
					</div>
					<div className={classes.wrapper}>
						<Field
							name="idCategories"
							component={CustomizedInputs}
							className={classes.inputField}
							type="text"
							placeholder="Введіть ID категорії"
							label=""
							rows={"1"}
							disabled={props.loadStatus ? true : false}
							value={idCategories}
							onChange={handleInputChange}
						/>

						<div>
							<Button
								className={classes.button}
								text={
									props.loadStatus ? (
										<Loading size={22} color={"#fff"} />
									) : (
										"Додати до списку"
									)
								}
								disabled={props.loadStatus ? true : false}
								handleClick={handleAddCategory}
							/>
						</div>
					</div>
				</div>
				<div className={classes.categoriesWrap}>
					<div className={classes.headerWrapper}>
						<Typography className={classes.typography}>
							Продукти, на які не застосовується промокод
						</Typography>
						<Popover
							className={classes.popover}
							title=""
							hiddenTitle="Список продуктів на які не застосовується промокод"
						/>
					</div>
					<div className={classes.chips}>
						<ul className={classes.chipsList}>
							{noPromocodeForProductsIds.map((data, index) => {
								return (
									<li key={index} className={classes.chipsListLi}>
										<Chip
											label={data}
											onDelete={handleDeleteProduct(data)}
											className={classes.chip}
										/>
										{isMin600 && <hr className={classes.hr} />}
									</li>
								);
							})}
						</ul>
					</div>
					<div className={classes.wrapper}>
						<Field
							name="idProducts"
							component={CustomizedInputs}
							className={classes.inputField}
							type="text"
							placeholder="Введіть ID товару"
							label=""
							rows={"1"}
							disabled={props.loadStatus ? true : false}
							value={idProducts}
							onChange={handleInputChange}
						/>

						<div>
							<Button
								className={classes.button}
								text={
									props.loadStatus ? (
										<Loading size={22} color={"#fff"} />
									) : (
										"Додати до списку"
									)
								}
								disabled={props.loadStatus ? true : false}
								handleClick={handleAddProduct}
							/>
						</div>
					</div>
				</div>
				<div className={classes.buttonWrapper}>
					<Button
						fontSize={22}
						color={"#fff"}
						className={classes.button}
						text="Збереги"
						handleClick={handleSubmit((data) => {
							submitForm(data);
						})}
					/>
				</div>
			</form>
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	dialogPaper: {
		width: "80%",
		maxWidth: 1057,
		padding: 24,
	},
	dialogTitle: {
		padding: 0,
		"& h2": {
			fontSize: 16,
			fontWeight: "normal",
			lineHeight: "25px",
		},
	},
	iconsWrapper: {
		display: "flex",
	},
	closeIcon: {
		marginRight: 16,
		color: "#476282",
		"&:hover": {
			color: "black",
			cursor: "pointer",
		},
	},
	buttonWrapper: {
		margin: "0 auto",
		marginTop: 30,
		textAlign: "center",
	},
	discountRadios: {
		marginTop: 30,
		[theme.breakpoints.down("600")]: {
			marginTop: 16,
		},
	},
	discountInput: {
		display: "flex",
		flexDirection: "column",
		height: 57,
		width: "100%",
		padding: "0 16px 16px 16px",
		marginBottom: 16,
		boxSizing: "border-box",

		boxShadow: "0px 14px 23px rgba(232, 228, 228, 0.5)",
		background: "white",
		borderRadius: 5,
	},
	discountTitle: {
		fontSize: 16,
		fontWeight: 500,
		lineHeight: "57px",
		color: "black",
	},
	discountInputWrap: {
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		cursor: "pointer",
	},
	discountRadio: {
		margin: 0,
		cursor: "pointer",
	},
	discountHidden: {
		width: 328,
		[theme.breakpoints.down("600")]: {
			width: "100%",
		},
	},
	otherFields: {},
	code: {},
	otherInput: {
		width: "100%",
		marginTop: 8,
		"& input": {
			width: "100%",
			height: 45,
			border: "1px solid #DADCE0",
			borderRadius: "5px",
			padding: "11px 50px 11px 16px",
			boxSizing: "border-box",
		},
		"& .MuiInput-underline:hover:not(.Mui-disabled):before": {
			content: "none",
		},
		"& .MuiInput-underline:after": {
			content: "none",
		},
		"& .MuiInput-underline:before": {
			content: "none",
		},
		"& .Mui-error input": {
			border: "1px solid red",
		},
	},
	textarea: {
		maxWidth: "327px",
		width: "100%",
		[theme.breakpoints.down("600")]: {
			maxWidth: "100%",
		},
		"& > div:before": {
			display: "none",
		},
		"& > div": {
			// width: '327px',
			marginTop: 8,
			resize: "none",
			// height: 103,
			border: "1px solid #DADCE0",
			borderRadius: "5px",
			padding: "11px 16px 11px 16px",
			boxSizing: "border-box",
			fontSize: 16,
			fontFamily: "Roboto, Helvetica, Arial, sans-serif",
			lineHeight: "24px",
			[theme.breakpoints.down("600")]: {
				width: "100%",
			},
		},
		"& > div:after": {
			display: "none",
		},
		"& > div.Mui-error": {
			border: "1px solid red;",
		},
		"&::-webkit-input-placeholder": {
			color: "#DADCE0",
			fontWeight: 400,
		},
		"&::textarea:-moz-placeholder": {
			color: "#DADCE0",
			fontWeight: 400,
		},
		"&::textarea::-moz-placeholder": {
			color: "#DADCE0",
			fontWeight: 400,
		},
		"&::textarea:-ms-input-placeholder": {
			color: "#DADCE0",
			fontWeight: 400,
		},
		"&::textarea::placeholder": {
			color: "#DADCE0",
			fontWeight: 400,
		},
	},
	otherInputDescription: {
		width: "100%",
		marginTop: 8,
		"& input": {
			width: "100%",
			height: 103,
			border: "1px solid #DADCE0",
			borderRadius: "5px",
			padding: "11px 50px 11px 16px",
			boxSizing: "border-box",
		},
		"& .MuiInput-underline:hover:not(.Mui-disabled):before": {
			content: "none",
		},
		"& .MuiInput-underline:after": {
			content: "none",
		},
		"& .MuiInput-underline:before": {
			content: "none",
		},
		"& .Mui-error input": {
			border: "1px solid red",
		},
	},
	checkbox: {
		"&.MuiCheckbox-colorPrimary.Mui-checked": {
			color: "#326dff",
		},
		"&.MuiCheckbox-colorPrimary": {
			padding: 10,
		},
	},
	headerWrapper: {
		display: "flex",
		position: "relative",
		maxWidth: 438,
	},
	orderLimitWrapper: {
		display: "flex",
		position: "relative",
		maxWidth: 320,
		[theme.breakpoints.down("600")]: {
			maxWidth: "100%",
		},
	},
	orderLimitPopover: {
		marginTop: 16,
	},
	popover: {
		top: 2,
	},
	categoriesWrap: {
		marginTop: 24,
		padding: 16,
		minHeight: 132,

		border: "1px solid #DADCE0",
		boxSizing: "border-box",
		borderRadius: 5,
		background: "white",
	},
	typography: {
		fontSize: 18,
		fontWeight: 500,
		lineHeight: "21px",
		paddingRight: 20,
	},
	wrapper: {
		display: "flex",
		alignItems: "end",
		[theme.breakpoints.down("600")]: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
		},
	},
	categoriesContent: {
		display: "flex",
		[theme.breakpoints.down("600")]: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
	},
	inputField: {
		"& input": {
			width: 328,
			height: 45,
			border: "1px solid #DADCE0",
			borderRadius: "5px",
			padding: "11px 50px 11px 16px",
			boxSizing: "border-box",
		},
		"& .MuiInput-underline:hover:not(.Mui-disabled):before": {
			content: "none",
		},
		"& .MuiInput-underline:after": {
			content: "none",
		},
		"& .MuiInput-underline:before": {
			content: "none",
		},
		"& .Mui-error input": {
			border: "1px solid red",
		},
		[theme.breakpoints.down("375")]: {
			"& > div > input": {
				width: "100%",
			},
		},
	},
	button: {
		marginTop: 0,
		marginLeft: 15,
		fontSize: 14,
		textTransform: "inherit",
		[theme.breakpoints.down("600")]: {
			marginTop: 16,
			marginLeft: 0,
		},
	},

	chipsList: {
		paddingLeft: 0,
		display: "flex",
		flexWrap: "wrap",
		[theme.breakpoints.down("600")]: {
			width: "100%",
			display: "flex",
			flexDirection: "column",
			"& > li > div": {
				width: "100%",
			},
			"& > li:last-child > hr": {
				display: "none",
			},
		},
	},
	chipsListLi: {
		listStyleType: "none",
		marginRight: 5,
		marginBottom: 5,
		[theme.breakpoints.down("600")]: {
			marginRight: 5,
		},
	},
	chip: {
		fontSize: 12,
		lineHeight: 1,
		color: "white",
		backgroundColor: "#326DFF",
		[theme.breakpoints.down("600")]: {
			display: "flex",
			justifyContent: "space-between",
			background: "#E0E9FF",
			color: "#326DFF",
			padding: "0 6px",
		},

		"&.MuiChip-deletable > .MuiChip-deleteIcon": {
			color: "rgba(255, 255, 255, .26)",
			[theme.breakpoints.down("600")]: {
				color: "rgba(255, 255, 255, 1)",
			},
		},
	},
	hr: {
		border: "1px dashed rgba(71, 98, 130, .25)",
		boxSizing: "border-box",
		marginLeft: "10px",
		marginRight: "10px",
	},
}));

CreateModalPromocodesForm = reduxForm({
	form: "CreatePromocodeForm",
	enableReinitialize: true,
})(CreateModalPromocodesForm);

export default CreateModalPromocodesForm;
