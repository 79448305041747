import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import Categories from './listCategories';
import CardProduct from './cardProduct';
import SortIcon from '@material-ui/icons/Sort';
import * as ROUTES from '../../../constants/routes';
import Language from './language';
import EditForm from './EditForm/edit';
import EditProduct from './EditProduct/editProductContainer';

import s from './edit.module.css';

const EditProducts = (props) => {
    const classes = useStyles();
    const path = props.match.params.id ? props.match.url : props.match.url + '/category' + props.activeCategory;
    // const [categoriesState, setCategoriesState] = useState([]);
    let firstCitySpotId = props.cities && props.cities[props.cities.length - 1] && props.cities[props.cities.length - 1].spot_id;

    // console.log('path', path);
    // useEffect(() => {
    //     const {categories, hiddenCategories} = props;

    //     let filtredCategories = [...categories];

    //     if (props.categories && props.hiddenCategories) {
    //        for (let key = 0; key < categories.length; key++) {
    //             let item = categories[key];

    //             for (let catId in hiddenCategories) {
    //                 let hiddenItem = hiddenCategories[catId];

    //                 if (item.id === hiddenItem) {
    //                     filtredCategories[key] = {
    //                         ...filtredCategories[key],
    //                         open: true,
    //                     }
    //                 } 
    //             }
    //        }

    //         setCategoriesState(filtredCategories);
    //     }
    // }, [props.categories, props.hiddenCategories]);

 
    const handleOffProduct = (id) => {
        let data = props.defaultHiddenProducts ? props.defaultHiddenProducts : [];

        if (!data.includes(id)) {
            data.push(id);
        } else {
            data = data.filter(item => item !== id); 
        }

        props.hiddenProduct(data); 
    }

    useEffect(() => {
        const {editModal, history, editId} = props;

        if (editModal) {
            history.push(`/page/menu/products/edit/${editId}`)
        }
    }, [props.editModal]);

    let categoryId = window.location.pathname.split('/');
    categoryId = categoryId[categoryId.length - 1].split('category')[1];
    categoryId = categoryId ? categoryId : null;

    useEffect(() => {
        if (categoryId && props.activeCategory) {
            if (categoryId !== props.activeCategory) {
                props.changeActiveCategory(categoryId);
                props.updateCategory(categoryId);
                // console.log('active -- >>>', categoryId);
            }
        }

    }, [props.activeCategory, categoryId]);


    return(
        <div className={s.container}>
        {props.categories ? <Categories categories={props.categories}
                                hiddenCategories={props.hiddenCategories}
                                language={props.language}
                                defaultLanguage={props.defaultLanguage}
                                open={props.open} 
                                activeCategory={props.activeCategory}
                                updateCategory={props.updateCategory}
                        /> : 'Категорий пока что нету'}
            
            <div className={s.productContainer}>
                {props.products && props.products.sort((a, b) => a.order < b.order ? -1 : 1).map((item, index) => {
                    if (item.menuCategoryId === props.activeCategory) {
                        // console.log('item', item)
                        return (
                            <CardProduct open={props.open} item={item} key={index}
                                        handleOffProduct={handleOffProduct}
                                        defaultHiddenProducts={props.defaultHiddenProducts}
                                        language={props.defaultLanguage}
                                        firstCitySpotId={firstCitySpotId}
                                        hiddenProducts={props.hiddenProducts}
                                        hiddenProduct={props.hiddenProduct}
                                        deleteProduct={props.deleteProduct}
                                        statusEditModal={props.statusEditModal}/>
                        )
                    } else {
                        return null;
                    }
                })}
            </div>
            {/* <Language   languageModal={props.languageModal} 
                        language={props.language}
                        languages={props.languages}
                        getCategories={props.getCategories}
                        statusLanguageModal={props.statusLanguageModal}
                        updateLanguage={props.updateLanguage}/> */}

            {/* {props.editModal ? <EditForm language={props.language}
                                         citys={props.citys}
                                         languages={props.languages}
                                         defaultLanguage={props.defaultLanguage}
                                         updateLanguage={props.updateLanguage} 
                                         categories={props.categories}
                                         statusEditModal={props.statusEditModal}
                                         product={props.products.find(x => x.id === props.editId)}
                                         onSubmit={props.onSubmit}
                      /> : null} */}
            {/* <Link to={`${path}/sort-mode`} className={classes.openModal}>
                <SortIcon className={classes.icon} />
            </Link> */}
        </div>
    )
}

const useStyles = makeStyles({
    icon: {
        width: 35,
        height: 35,
        padding: 5,
        background: '#326dff',
        color: 'white',
        borderRadius: '50%',
        position: 'fixed',
        bottom: 15,
        right: 15,
        backgroundImage: 'url(../../assets/img/plus.svg)',
        backgroundPosition: 'center',
        backgroundSize: 20,
        backgroundRepeat: 'no-repeat',
        opacity: 0.7,
        transition: 'all 0.5s',
        cursor: 'pointer',
        '&:hover': {
            opacity: 1,
        }
    }
});

export default withRouter(EditProducts);