import React from 'react';

import DefaultScreen from './defaultScreen';
import { AuthUserContext, withAuthorization } from '../../sessions';


const DefaultScreenContainer = () => {
        return(
            <AuthUserContext.Consumer>
                 {authUser => (
                    <DefaultScreen/>
                    )}
            </AuthUserContext.Consumer>
        )
} 

const condition = authUser => !!authUser;
export default withAuthorization(condition)(DefaultScreenContainer);
