import React, { useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";

import TabMobile from "../../../components/TabMobile";
import CategoriesForm from "./categoriesForm";
import ProductsForm from "./productsForm";
import Typography from "@material-ui/core/Typography";

const PopoverComponent = (props) => {
	const classes = useStyles();
	const [active, setActive] = useState(0);
	const {
		hiddenCategories,
		hiddenProducts,
		updateVisibility,
		loadStatus,
		isActiveCategoriesSnackbar,
		isActiveMenusSnackbar,
		saveData,
	} = props;
	const isMin600 = useMediaQuery("(max-width: 599px)");
	const isMax600 = useMediaQuery("(min-width: 600px)");

	const handleTabs = (el) => {
		const id = el.target.id;
		setActive(Number(id));
	};

	return (
		<div>
			<div className={classes.tabWrapper}>
				{isMin600 && (
					<Typography className={classes.titleMobile} variant="h1">
						Меню
					</Typography>
				)}
				<div className={classes.tabContent}>
					{isMin600 && (
						<>
							<TabMobile
								data={["Категорії", "Товари"]}
								active={active}
								handleTabs={handleTabs}
							/>

							{active === 0 && (
								<CategoriesForm
									hiddenCategories={hiddenCategories}
									updateVisibility={updateVisibility}
									loadStatus={loadStatus}
									isActiveCategoriesSnackbar={isActiveCategoriesSnackbar}
									saveData={saveData}
								/>
							)}
							{active === 1 && (
								<ProductsForm
									hiddenProducts={hiddenProducts}
									changeVisibleProduct={props.changeVisibleProduct}
									products={props.products}
									updateVisibility={updateVisibility}
									isActiveMenusSnackbar={isActiveMenusSnackbar}
									loadStatus={loadStatus}
									saveData={saveData}
								/>
							)}
						</>
					)}
					{isMax600 && (
						<div className={classes.desctopWrapper}>
							<Typography className={classes.title} variant="h1">
								Меню
							</Typography>
							<CategoriesForm
								hiddenCategories={hiddenCategories}
								updateVisibility={updateVisibility}
								loadStatus={loadStatus}
								isActiveCategoriesSnackbar={isActiveCategoriesSnackbar}
								saveData={saveData}
							/>

							<ProductsForm
								hiddenProducts={hiddenProducts}
								changeVisibleProduct={props.changeVisibleProduct}
								products={props.products}
								updateVisibility={updateVisibility}
								isActiveMenusSnackbar={isActiveMenusSnackbar}
								loadStatus={loadStatus}
								saveData={saveData}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	tabWrapper: {},
	desctopWrapper: {
		padding: "24px 16px 24px 16px",
		background: "white",
		border: " 0.5px solid #DADCE0",
		boxSizing: "border-box",
		borderRadius: "5px 5px 0px 0px",
	},
	tabContent: {
		[theme.breakpoints.down("600")]: {
			padding: "16px 8px",
			background: "white",
			border: "1px solid #E5E5E5",
			borderRadius: 10,
		},
	},
	title: {
		marginBottom: 16,
		fontSize: 22,
		fontWeight: "bold",
		lineHeight: "29px",
	},
	titleMobile: {
		marginTop: 16,
		marginBottom: 8,
		fontSize: 22,
		fontWeight: "bold",
		lineHeight: "29px",
	},
}));

export default PopoverComponent;
