import React from "react";
import { Field, reduxForm } from "redux-form";
import s from "./login.module.css";
import Button from "../../components/Button";
import CustomizedInputs from "../../components/UIform";
import Loading from "../../components/Loading";
import { required } from "redux-form-validators";

let AuthForm = (props) => {
	const { handleSubmit, onReturn } = props;

	return (
		<form onSubmit={handleSubmit} autoComplete="off">
			<div className={s.title}>E-APP</div>
			<Field
				name="email"
				component={CustomizedInputs}
				type="email"
				placeholder="Email"
				disabled={props.loadStatus ? true : false}
				validate={required({ msg: "Это поле обязательно для заполнения" })}
			/>

			<Field
				name="password"
				component={CustomizedInputs}
				type="password"
				placeholder="Пароль"
				disabled={props.loadStatus ? true : false}
				validate={required({ msg: "Это поле обязательно для заполнения" })}
			/>

			<div className={s.buttonsWrap}>
				<Button
					text={
						props.loadStatus ? <Loading size={22} color={"#fff"} /> : "Войти"
					}
					disabled={props.loadStatus ? true : false}
					handleClick={handleSubmit}
				/>
				<Button handleClick={onReturn} text="Назад" />
			</div>
		</form>
	);
};

AuthForm = reduxForm({
	form: "AuthForm",
})(AuthForm);

export default AuthForm;
