import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withFirebase } from '../../../firebase';
import { withRouter } from 'react-router-dom';

import { updateBrandData, editShopsNewStructure } from '../../../reducers/shops';
import ProductsSort from './productsSort';

const Products = (props) => {
    const { categories, categoriesOrder, products, hiddenProducts, isNewAdmin} = props;
    const dispatch = useDispatch();
    const [categoriesList, setCategoriesList] = useState([])

    const sortedAndRemainingCategories = useMemo(() => {
        const sortedCategories = categoriesOrder.map(orderId => categories.find(cat => cat.id === orderId)).filter(cat => cat);
        const remainingCategories = categories.filter(cat => !categoriesOrder.includes(cat.id)).sort((a, b) => a.order - b.order);
        return sortedCategories.concat(remainingCategories);
    }, [categories, categoriesOrder]);

    useEffect(() => {
        setCategoriesList(sortedAndRemainingCategories);
    }, [sortedAndRemainingCategories]);

    const handleToggleHidden = useCallback((categoryId) => {
        const newHiddenProducts= hiddenProducts.includes(categoryId)
            ? hiddenProducts.filter(id => id !== categoryId)
            : [...hiddenProducts, categoryId];
    
        const keyCity = JSON.parse(localStorage.getItem("chosenCity"));
        const keyBrand = JSON.parse(localStorage.getItem("chosenBrand"));

        if (isNewAdmin) { 
        const path = `web/settings/city/${keyCity}/brands/${keyBrand}/category/hiddenProducts`;
    
        props.firebase.bd.ref(path).set(newHiddenProducts)
            .then(() => {
                console.log('Hidden products updated successfully');
                dispatch(updateBrandData({
                    shopKey: keyCity,
                    brandKey: keyBrand,
                    path: "category.hiddenProducts",
                    value: newHiddenProducts,
                })); 
            })
            .catch(error => {
                console.error("Error updating hidden products: ", error);
            });
        } else {
            const path = `web/settings/city/${keyCity}/category/hiddenProducts`;
    
            props.firebase.bd.ref(path).set(newHiddenProducts)
                .then(() => {
                    console.log('Hidden products updated successfully');
                    dispatch(editShopsNewStructure({
                        key: keyCity,
                        param1: "category",
                        param2: "hiddenProducts",
                        value: newHiddenProducts,
                    }))
                })
                .catch(error => {
                    console.error("Error updating hidden products: ", error);
                });
        }
    }, [hiddenProducts, dispatch, props.firebase]);

    return (
        <ProductsSort 
            categories={categoriesList}
            products={products}
            hiddenProducts={hiddenProducts}
            handleToggleHidden={handleToggleHidden}
        />
    )
}

export default withRouter(withFirebase((Products)));
