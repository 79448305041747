export const checkFields = (data) => {
    let newData = {}

    if (data.name) newData.name = data.name;
    if (data.delivery) newData.delivery = Number(data.delivery);
    if (data.deliveryItemId) newData.deliveryItemId = data.deliveryItemId;
    if (data.deliveryLimit) newData.deliveryLimit = Number(data.deliveryLimit);
    if (data.deliveryFreeLimit) newData.deliveryFreeLimit = Number(data.deliveryFreeLimit);

    return newData;
}

export const getInitialValues = (props) => {
    const {deliveryZone, shops, delivery} = props;

    let initialValues = {};

    if (delivery === 1) {
        initialValues = {
            delivery: shops.delivery,
            deliveryFreeLimit: shops.deliveryFreeLimit,
            deliveryLimit: shops.deliveryLimit,
        }
    }

    if (delivery === 2) {
        initialValues = {
        ...deliveryZone, 
        }
    }

    return initialValues;
}