import React from 'react';
import s from './page.module.css'

const DefaultPage = () => {
    return (
        <div className={s.wrapper}>
        </div>
    )
}

export default DefaultPage;