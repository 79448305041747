import React from "react";
import { Field, reduxForm } from "redux-form";
import s from "./login.module.css";
import Button from "../../components/Button";
import CustomizedInputs from "../../components/UIform";
import Loading from "../../components/Loading";
import { required } from "redux-form-validators";

const LoginForm = (props) => {
	const { handleSubmit } = props;

	return (
		<form onSubmit={handleSubmit} autoComplete="off">
			<div className={s.title}>E-APP</div>
			<Field
				name="login"
				component={CustomizedInputs}
				type="text"
				placeholder="Ваш заклад"
				disabled={props.loadStatus ? true : false}
				validate={required({ msg: "Це поле є обов'язковим для заповнення" })}
			/>

			<Button
				text={
					props.loadStatus ? <Loading size={22} color={"#fff"} /> : "Продовжити"
				}
				disabled={props.loadStatus ? true : false}
				handleClick={handleSubmit}
			/>
		</form>
	);
};

export default reduxForm({
	form: "LoginForm",
})(LoginForm);
