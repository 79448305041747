import React from 'react';
import s from './formControls.module.css';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { fromRenderProps } from 'recompose';

export const Input = ({input, meta, ...props}) => {
    let value = input.value;

    if(props.val !== undefined && value.length === 0 && meta.visited === false){
        value = props.val;
    }

    // if (props.defaultPrice) {
    //   value = props.defaultPrice

    //   input = {
    //     ...input,
    //     value: props.defaultPrice 
    //   }
    // }

    return(
    <div className={s.inputWrapper}>
            <label htmlFor={props.name}>{props.label}</label>
            <input {...input} value={value} {...props} placeholder={props.label} type={props.typeField} />
            {meta.touched && (meta.error && <span>{meta.error}</span>)}
            {meta.touched || meta.submitFailed && (meta.error && <span>{meta.error}</span>)}
    </div>
    )
}

export const Checkbox = ({input, meta, ...props}) => {
  return(
  <div className={s.inputWrapperCheckbox}>
          <input checked={input.value ? true : false} {...input} {...props} type={'checkbox'}/>
          <label htmlFor={props.name}>{props.label}</label>
  </div>
  )
}

export const Textarea = ({input, meta, ...props}) => {
  var value = input.value;
  if(props.val !== undefined && value.length === 0 && meta.visited === false){
      value = props.val;
  }
  return(
  <div className={s.inputWrapper}>
          <label htmlFor={props.name}>{props.label}</label>
          <textarea {...input} value={value} {...props} placeholder={props.label}/>
  </div>
  )
}

export const File = ({ input, type, meta, ...props }) => {
    delete input.value;
    return (
      <div className={s.inputWrapper}>
        <label htmlFor={input.name}> {props.label} </label>
          <input {...input} {...props} type={type} />
          {meta.touched && (meta.error && <span>{meta.error}</span>)}
      </div>
    )
  }

  export const Select = ({input, meta, ...props}) => {
    let option = {};

    for (let key in props.option) {
      let item = props.option[key];
      option[key] = item
    }

    let optionKeys = Object.keys(option);
    let optionValues = Object.values(option);

    if (props.trigger === 'addProduct') {

      // if (!input.value && props.selectedCategory) {
      //   input.value = props.selectedCategory;
      // }

      // console.log('input.value', input.value);

      // if (input.value !== props.selectedCategory) {
      //   props.setSelectedCategory(input.value);
      // }
    }

    // console.log('optionKeys', optionKeys);
    // console.log('optionValues', optionValues);
    
    return(
      <div className={s.inputWrapper}>
              <label htmlFor={props.name}>{props.label}</label>
              <select {...input}>
                <option value="">Выберите категорию</option><ArrowDropDownIcon />
                {optionKeys.map((key, index) => 
                  <option key={key} value={key}>{optionValues[index].name[props.defaultLanguage] ? optionValues[index].name[props.defaultLanguage] : optionValues[index].name}</option>)}
              </select>
              {meta.touched && (meta.error && <span>{meta.error}</span>)}
              {meta.touched || meta.submitFailed && (meta.error && <span>{meta.error}</span>)}
      </div>
    )
}