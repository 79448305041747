import React, {useEffect, useState} from 'react';
import {Input, File, Textarea, Select} from '../../ui/Input/';
import {reduxForm, reset, Field, isValid, formValueSelector} from 'redux-form';
import {required, numericality} from 'redux-form-validators';
import {connect, useSelector} from 'react-redux';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Popover from '../../../../components/Popover';

import { validateImage, trim } from './other';
import s from './products.module.css';
import useStyles from './styles';

let EditForm = (props) => {
    const classes = useStyles();
    const [isValid, setIsValid] = useState(null);
    const [citiesState, setCitiesState] = useState(null);
    const cities = useSelector(state => state.shops.shops);
    const categories = useSelector(state => state.shops.categories);
    const isOneCity = cities ? cities.length === 1 : false;
    const isOneTerminals = isOneCity && cities[0].deliveryTerminals.length === 1;

    const [tabActive, setTabActive] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [filtredTerminals, setFiltredTerminals] = useState([]);

    let {isOpenCities, handleIsOpenCities} = props;

    const handleTabClick = (count) => {
        const defaultLanguage = props.defaultLanguage;
        const languages = props.languages;
        let title = languages.find(item => item.key === defaultLanguage).label.split(' ');
        title = title[title.length - 1];
        if (count === 0) {
          setTabActive(count);
        }

        if (isValid) {
          setTabActive(count);
        } else {
          if (count !== 0) {
            alert(`Сначала добавьте товар на ${title} языке`)
          }
        }
      }

      const filterTerminals = () => {
        const filtredTerminals = [];
        let categorySpots = {};
        let categorySpotsData = Object.keys(categories).length > 0 && categories[selectedCategory].spots;

        for (let key in categorySpotsData) {
          const categorySpot = categorySpotsData[key];

          if (categorySpot.visible === "1") {
            categorySpots[key] = categorySpot;
          }
        }
  
        for (let key in categorySpots) {
          const categorySpot = categorySpots[key];

          props.terminals.map((terminal) => {
            const productSpot = props.product.spots.find(spot => spot.spot_id === terminal.id);
            // console.log('productSpot', productSpot);
            // console.log('categorySpot', productSpot);
            if (Number(terminal.id) === Number(categorySpot.spot_id)) {
              // console.log('terminal', terminal);

              if (productSpot) {
                filtredTerminals.push(terminal);
              } else {
                filtredTerminals.push({ ...terminal, visible: categorySpot.visible });
              }
            }
          });
        }

        return filtredTerminals;
      }

      useEffect(() => {
        if (selectedCategory) {
          let isLoad = false;
          const categorySpots = categories.length > 0 && categories[selectedCategory].spots;
  
          if (filtredTerminals.length === 0) {
            setFiltredTerminals(filterTerminals())
          }
  
          filtredTerminals.map(filtredTerminal => {
            for (let key in categorySpots) {
              const categorySpot = categorySpots[key];
  
              if (filtredTerminal.id !== categorySpot.spot_id) {
                isLoad = true;
              }
            }
          });
  
          if (isLoad) {
            setFiltredTerminals(filterTerminals());
            isLoad = false;
          }
        }
      }, [selectedCategory, props.terminals]);

      useEffect(() => {
        setIsValid(props.valid);
      }, [props.valid]);

      useEffect(() => {
        if (!!props.selectedCategory && selectedCategory !== props.selectedCategory) {
          setSelectedCategory(props.selectedCategory);
        }
      }, [selectedCategory, props.selectedCategory]);

      useEffect(() => {
        if (isOneCity && isOneTerminals) handleIsOpenCities();
      }, [isOneTerminals, isOneCity]);

      const handleCheckboxChange = (e) => {
        let arr = [...filtredTerminals];
        let id = e.target.id;
        // let index = citiesState.findIndex(item => item.spot_id === id);
        let item = filtredTerminals[id];

        if (!item.visible) {
          item = {
            ...filtredTerminals[id],
            visible: "0",
            name: item.name
          }
        }
  
        item = { 
            ...item, 
            visible: item.visible == "0" ? "1" : "0",
        };

        arr[id] = item;

        setFiltredTerminals(arr);  
      }

      const handleSubmitForm = () => {
        const {handleChangeCitiesSpot} = props;

        let newCitiesState = [];

        filtredTerminals.map(item => {
          newCitiesState.push({
            ...item,
            price: "0"
          });
        });

        handleChangeCitiesSpot(newCitiesState);
        setCitiesState(newCitiesState);
        setSelectedCategory(null);
      }

    // console.log('props', props);
    // console.log('selectedCategory', selectedCategory);
    console.log('categories', categories);
    console.log('filtredTerminals', filtredTerminals);
    // console.log('initialValues', props.initialValues);

    return (
        <div className={s.form}>
            <form className={s.formContainer}  autoComplete='off' onSubmit={props.handleSubmit} initialValues={props.initialValues}>
                <span className={s.title}></span>
                    <Field 
                        name={'categories'}
                        defaultLanguage={props.defaultLanguage}  
                        option={props.categories}
                        checkedCategory={props.product.menuCategoryId} 
                        component={Select} label={'Категория*'} 
                        validate={required({msg: 'Это поле обязательно для заполнения'})}/> 
              
                    <div className={classes.tabs}>
                        {props.languages.map((item, index) => {
                            let name = item.label.split(' ');
                            name = name[name.length - 1];
                            name = name.replace('ом', 'ий');

                            return (
                                <div style={{
                                    background: tabActive === index ? '#255ce5' : null
                                }} onClick={() => handleTabClick(index)} className={classes.tab}>{name}</div>
                            )
                        })}
                    </div>
                    {props.languages.map((item, index) => {
                        let name = 'name' + '_' + item.key;
                        let description = 'description' + '_' + item.key;

                        return (
                            <div style={{
                                display: tabActive === index ? 'block' : 'none'
                            }}>
                                <Field 
                                    name={name}
                                    component={Input} label={'Название*'} 
                                    validate={index === 0 && required({msg: 'Это поле обязательно для заполнения'})} />
                                <Field
                                    className={classes.textarea} 
                                    name={description}
                                    component={Textarea} label={'Описание'} />
                            </div>
                        )
                    })}
                    <Field 
                        name={`firstPrice`}
                        typeField="text"
                        component={Input} 
                        // onChange={handlePriceChange}
                        label={'Цена*'} 
                        disabled={isOpenCities}
                        validate={[
                          required({msg: 'Это поле обязательно для заполнения'}),
                          numericality({ msg: 'Поддерживаються только целые числа' })

                        ]} />
                    <Field 
                        name={`photo`}
                        component={File} label={'Новое фото'} 
                        validate={validateImage} 
                        type={'file'}/>

                    {!isOneTerminals && (
                      <>
                        <div className={classes.openCitiesCheckbox}>
                          <FormControlLabel
                              control={
                              <Checkbox 
                                  checked={isOpenCities} 
                                  onChange={handleIsOpenCities}
                              />
                              }
                              label="Разные цены в заведениях"
                              className={s.citiesLabel}
                          /> 
                        </div>
                        <div className={classes.isOpenCities} style={{
                          display: isOpenCities ? 'block' : 'none'
                        }}>
                            <div className={classes.headerWrapper}>
                              <div className={classes.shop}>Заведение:</div>
                              <div className={classes.price}>Цена:</div>
                            </div>
                            {filtredTerminals.map((item, index) => {
                              return (
                                <div className={classes.citiesPrice}>
                                  <div className={classes.citiesName}>
                                      <div className={classes.popoverWrapper}>
                                        <strong className={classes.nameLine}>{trim(item.address, 100)}</strong>
                                      </div>
                                  </div>
                                  <div className={classes.leftPanel}>
                                    <div className={classes.citiesFields}>
                                      <Field 
                                            name={`terminals.${item.id}`}
                                            className={classes.citiesField}
                                            component={Input}
                                            // label={'Цена*'}  
                                          />
                                    </div>
                                    <div className={classes.citiesCheckbox}>
                                      <FormControlLabel
                                          control={
                                            <Checkbox 
                                              id={index}
                                              checked={item.visible === "1" ? true : false} 
                                              onChange={handleCheckboxChange}
                                            />
                                          }
                                          label="Отображать"
                                          className={classes.citiesLabel}
                                      />
                                    </div>
                                  </div>
                                </div>
                                )
                              })}
                        </div>
                      </>
                    )}
                <button onClick={() => handleSubmitForm()} type="submit">Сохранить</button>
            </form>
        </div>
    )
}
  
const afterSubmit = (result, dispatch) =>
  dispatch(reset('EditForm'));

const selector = formValueSelector('EditForm');
const mapStateToProps = (state, props) => {
  const selectedCategory = selector(state, 'categories');
  let terminals = [];
  let initialValues = { terminals: {} };
  const cities = state.shops.shops;

  let names = props.product.name ? props.product.name : {[props.defaultLanguage]: ''};
  let descriptions = props.product.description ? props.product.description : {[props.defaultLanguage]: ''};
  let data = props.languages;
  let namesInitial = {};
  let descriptionsInitial = {};

  data.map(item => {
      let name = 'name' + '_' + item.key;
      let description = 'description' + '_' + item.key;

      namesInitial = {
          ...namesInitial,
          [name]: names[item.key] ? names[item.key] : null,
      };

      descriptionsInitial = {
          ...descriptionsInitial,
          [description]: descriptions[item.key] ? descriptions[item.key] : null,
      };
  });

  initialValues = { 
    ...initialValues,
    ...namesInitial,
    ...descriptionsInitial,
    categories: props.product.menuCategoryId,
    firstPrice: props.product.spots.filter(spot => spot.visible === "1").length > 0 ? Number(props.product.spots.filter(spot => spot.visible === "1")[0].price / 100) : "0",
  };
  
  cities.map(city => {
    city.deliveryTerminals.map(terminal => {
      terminals.push({ ...terminal, cityId: city.spot_id });
    })
  });

  props.product.spots.map((spot, index) => {
    initialValues.terminals[spot.spot_id] =  Number(spot.price / 100);

    terminals.map((terminal, index) => {
      if (terminal.id === spot.spot_id) {
        terminals[index] = { ...terminal, visible: spot.visible };
      }
    });
  });

  if (selectedCategory) {
    const products = state.shops.products;
    const category = props.categories[selectedCategory];
    // console.log('category', category);
    for (let key in products) {
      const product = products[key];
      if (product.menuCategoryId === selectedCategory) {
        for (let productKey in products.spots) {
          const spot = products.spots[productKey];
          initialValues.terminals[spot.spot_id] = Number(spot.price / 100)
        }
        // product.spots.map((spot, index) => initialValues.terminals[spot.spot_id] = Number(spot.price / 100));
      }
    }
  }

  return {
    terminals,
    initialValues,
    selectedCategory,
    valid: isValid('ProductsForm')(state),
  };
}

export default connect(mapStateToProps, null)(reduxForm({
  form:'EditForm',
  onSubmitSuccess: afterSubmit,
})(EditForm));