/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

import disableApp from "../../assets/img/disableApp.png";
import AlertForm from "./alertForm";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Loading from "../../components/Loading";

const CustomSwitch = withStyles({
	switchBase: {
		color: "#326dff",
		"&$checked": {
			color: "#326dff",
		},
		"&$checked + $track": {
			backgroundColor: "#326dff",
		},
	},
	checked: {},
	track: {},
})(Switch);

const DisableApp = (props) => {
	const classes = useStyles();
	// const isOrderAvailable = useSelector(state => state.shops.shops[props.keyShop]?.appSettings?.isOrderAvailable)
	const shops = useSelector((state) => state.shops.shops);

	const [isOrderAvailable, setIsOrderAvailable] = useState(false); // Default state

	useEffect(() => {
		const activeBrand =
			props.isNewAdmin &&
			shops
				.flatMap((shop) => shop.brands)
				.find((brand) => brand.id === props.keyShop && brand.isActive);

		const available =
			props.isNewAdmin && shops
				? activeBrand?.appSettings?.isOrderAvailable
				: shops[props.keyShop]?.appSettings?.isOrderAvailable;

		setIsOrderAvailable(!!available);
	}, [props.isNewAdmin, props.keyShop, shops]);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={7}>
				<Typography variant="h6" className={classes.title}>
					Стан замовлень:
				</Typography>
				{props.shops ? (
					<FormGroup>
						<FormControlLabel
							control={
								<CustomSwitch
									checked={isOrderAvailable}
									onChange={() => props.updateStatusApp()}
									value={isOrderAvailable}
								/>
							}
							label="Увімкнути прийом замовлень"
						/>
					</FormGroup>
				) : (
					<Loading size={22} color={"#326dff"} />
				)}
				<AlertForm alert={props.alert} onSubmit={props.updateAlert} />
			</Grid>
			<Grid item xs={12} md={5}>
				<div className={classes.imgContainer}>
					<img className={classes.disableApp} src={disableApp} />
				</div>
			</Grid>
		</Grid>
	);
};
const useStyles = makeStyles((theme) => ({
	imgContainer: {
		width: "100%",
		height: "auto",
		display: "flex",
		justifyContent: "center",
	},
	disableApp: {
		width: "100%",
		maxWidth: 300,
		height: "auto",
		margin: "auto",
		marginBottom: "10px",
	},
	title: {
		marginBottom: 10,
		fontSize: 18,
	},
}));

export default DisableApp;
