import React, { Component } from "react";

import DisableApp from "./disableApp";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
	updateKeyShop,
	editShopsNewStructure,
	updateSettings,
	updateBrandData,
} from "../../reducers/shops";
import { withFirebase } from "../../firebase";

class DisableAppContainer extends Component {
	constructor() {
		super();

		this.state = {
			checked: true,
			keyShop: null,
			activeBrand: null,
			isNewAdmin: false,
		};
	}

	componentDidMount() {
		this.props.updateKeyShop(this.props.match.params.city);

		const adminPermission = JSON.parse(localStorage.getItem("adminPermission"));
		const isNewAdmin = adminPermission?.newAdmin;

		this.setState({
			keyShop: this.props.match.params.city,
			isNewAdmin: isNewAdmin,
		});

		this.updateActiveBrand(isNewAdmin);
	}

	componentDidUpdate(prevProps) {
		// Проверка изменения параметра city в URL
		if (prevProps.match.params.city !== this.props.match.params.city) {
			// Обновление keyShop в состоянии
			this.setState({
				keyShop: this.props.match.params.city,
			});

			// Обновление activeBrand, если пользователь является администратором
			this.updateActiveBrand(this.state.isNewAdmin);
		}

		// Проверка, изменились ли магазины (shops)
		if (prevProps.shops !== this.props.shops) {
			// Пересчитываем activeBrand только если isNewAdmin === true
			if (this.state.isNewAdmin) {
				this.updateActiveBrand(this.state.isNewAdmin);
			}
		}
	}

	updateActiveBrand = (isNewAdmin) => {
		if (isNewAdmin) {
			const activeBrand = this.props.shops
				.flatMap((shop) => shop.brands)
				.find(
					(brand) => brand.id === this.props.match.params.city && brand.isActive
				);
			this.setState({ activeBrand });
		}
	};

	updateStatusApp = () => {
		if (this.state.isNewAdmin) {
			let value = this.state.activeBrand.appSettings.isOrderAvailable
				? false
				: true;

			const currentCity = JSON.parse(localStorage.getItem("chosenCity"));
			const currentBrand = JSON.parse(localStorage.getItem("chosenBrand"));

			this.props.updateBrandData({
				shopKey: currentCity,
				brandKey: currentBrand,
				path: "appSettings.isOrderAvailable",
				value,
			});

			this.props.updateSettings({
				params: "isOrderAvailable",
				value,
			});

			this.props.firebase.bd
				.ref(
					`web/settings/city/${currentCity}/brands/${currentBrand}/appSettings/`
				)
				.update({ isOrderAvailable: value });
			setTimeout(() => alert("Готово"), 300);
		} else {
			let value = this.props.shops[this.props.match.params.city].appSettings
				.isOrderAvailable
				? false
				: true;

			this.props.editShopsNewStructure({
				key: this.props.match.params.city,
				param1: "appSettings",
				param2: "isOrderAvailable",
				value,
			});

			this.props.updateSettings({
				params: "isOrderAvailable",
				value,
			});

			this.props.firebase.bd
				.ref(`/web/settings/city/${this.props.match.params.city}/appSettings/`)
				.update({ isOrderAvailable: value });
			setTimeout(() => alert("Готово"), 300);
		}
	};

	updateAlert = (data) => {
		if (data.text !== this.props.appSettings.closedSpotAlertDescription) {
			if (this.state.isNewAdmin) {
				const currentCity = JSON.parse(localStorage.getItem("chosenCity"));
				const currentBrand = JSON.parse(localStorage.getItem("chosenBrand"));

				this.props.updateSettings({
					params: "closedSpotAlertDescription",
					value: data.text,
				});
				this.props.updateBrandData({
					shopKey: currentCity,
					brandKey: currentBrand,
					path: "appSettings.closedSpotAlertDescription",
					value: data.text,
				});
				this.props.firebase.bd
					.ref(
						`web/settings/city/${currentCity}/brands/${currentBrand}/appSettings`
					)
					.update({ closedSpotAlertDescription: data.text });
			} else {
				this.props.updateSettings({
					params: "closedSpotAlertDescription",
					value: data.text,
				});
				this.props.firebase.bd
					.ref(
						`/web/settings/city/${this.props.match.params.city}/appSettings/`
					)
					.update({ closedSpotAlertDescription: data.text });
			}
		}
		alert("Готово");
	};

	render() {
		return (
			<DisableApp
				updateStatusApp={this.updateStatusApp}
				keyShop={this.props.match.params.city}
				closedAlert={this.props.closedAlert}
				shops={
					this.state.isNewAdmin && this.state.activeBrand
						? this.state.activeBrand
						: this.props.shops[this.props.match.params.city]
				}
				// alert={this.props.appSettings.closedSpotAlertDescription}
				alert={
					this.state.isNewAdmin && this.state.activeBrand
						? this.state.activeBrand.appSettings.closedSpotAlertDescription
						: this.props.shops[this.props.match.params.city]?.appSettings
								?.closedSpotAlertDescription
				}
				editShops={this.editShops}
				updateAlert={this.updateAlert}
				isNewAdmin={this.state.isNewAdmin}
			/>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		shops: state.shops.shops,
		appSettings: state.shops.appSettings,
	};
};

export default connect(mapStateToProps, {
	updateKeyShop,
	editShopsNewStructure,
	updateSettings,
	updateBrandData,
})(withRouter(withFirebase(DisableAppContainer)));
